// @flow
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { Auth } from "../../actions/actions";
import ResetPWDPresentational from "./ResetPWDPresentational";
import "./ResetPassword.css";

type Props = {
  companyDomain: string,
  match: Object,
  error: string,
  submitting: boolean,
  valid: boolean,
  dirty: boolean,
  handleSubmit: Function,
  onResetPWD: Function,
  goTo: Function,
  reset: Function,
};

type State = {
  errorSuccess: string,
  hasLength: boolean,
  hasLowerCase: boolean,
  hasUpperCase: boolean,
  hasNumber: boolean,
  hasSymbols: boolean,
  strongerPassword: string,
};

class ResetPWDContainer extends React.Component<Props, State> {
  state = {
    errorSuccess: "",
    hasLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSymbols: false,
    strongerPassword: "vweak",
  };
  onStrongerPassword = (event, password) => {
    const hasLength = password.length > 7;
    const hasLowerCase = password.toUpperCase() !== password;
    const hasUpperCase = password.toLowerCase() !== password;
    const hasNumber = /\d/gi.test(password);
    const hasSymbols = /[^\w]/gi.test(password);
    const strongerPassword = [
      hasLength && hasLowerCase,
      hasLength && hasUpperCase,
      hasLength && hasNumber,
      hasLength && hasSymbols,
    ].filter((allowed) => allowed);
    let isStrongerPassword = "";
    switch (strongerPassword.length) {
      case 1:
        isStrongerPassword = "weak";
        break;
      case 2:
        isStrongerPassword = "soso";
        break;
      case 3:
        isStrongerPassword = "good";
        break;
      case 4:
        isStrongerPassword = "great";
        break;
      default:
        isStrongerPassword = "vweak";
    }
    this.setState({
      hasLength,
      hasLowerCase,
      hasUpperCase,
      hasNumber,
      hasSymbols,
      strongerPassword: isStrongerPassword,
    });
  };
  goToSignIn = () => {
    const { goTo } = this.props;
    goTo("/signin");
  };
  onSubmit = ({ password }) => {
    const {
      match: {
        params: { subdomain, token: recoveringToken },
      },
      onResetPWD,
      goTo,
    } = this.props;
    this.setState({
      errorSuccess: "",
    });
    return new Promise((resolve, reject) => {
      const formValues = {
        body: {
          recoveringToken,
          password,
          tenant: subdomain,
        },
      };
      const callbacks = {
        callbackError: (msg) => {
          window.logger.log("errors", msg);
          this.setState({
            errorSuccess: "error",
          });
          reject(new SubmissionError({ _error: msg }));
        },
        callbackSuccess: () => {
          window.logger.log("Success");
          this.setState({
            errorSuccess: "success",
          });
          setTimeout(() => {
            goTo({
              pathname: "/signin",
              state: { companyDomain: subdomain },
            });
          }, 5000);
          resolve();
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      onResetPWD(payload);
    });
  };
  componentWillMount() {
    if (document.body && document.body.className) {
      document.body.className = "website";
    }
  }
  render() {
    const {
      companyDomain,
      handleSubmit,
      error,
      submitting,
      valid,
      reset,
      dirty,
    } = this.props;
    return (
      <ResetPWDPresentational
        companyDomain={companyDomain}
        onSubmit={handleSubmit(this.onSubmit)}
        onStrongerPassword={this.onStrongerPassword}
        error={error}
        submitting={submitting}
        valid={valid}
        dirty={dirty}
        reset={reset}
        goToSignIn={this.goToSignIn}
        {...this.state}
      />
    );
  }
}

// decorate the form component
const ResetPWDForm = reduxForm({
  form: "reset-password",
  validate: (values) => {
    const errors = {};
    if (!values.password || values.password.length < 8) {
      errors.password = "Write your password. Must be 8 characters or more";
    }
    if (!values.confirmPassword || values.confirmPassword.length < 8) {
      errors.confirmPassword = "Repeat your password";
    }
    if (
      values.password &&
      values.confirmPassword &&
      values.confirmPassword.length >= 8 &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = "Password does not match";
      errors._error = "passwordNotMatch";
    }
    return errors;
  },
})(ResetPWDContainer);

const bindActions = (dispatch) => {
  return {
    onResetPWD: (payload) => dispatch(Auth.authResetPWD(payload)),
    goTo: (path) => dispatch(push(path)),
  };
};

export default connect(null, bindActions)(ResetPWDForm);
