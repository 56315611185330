// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RecoveryPWDContainer from "./RecoveryPWDContainer";
import ProtectedRoute from "../shared/ProtectedRoute";

const RecoveryPWDRoute: React.ComponentType<any> = withRouter(
  connect(
    ({ config, company: { companyDomains, companyDomainSelected }, auth }) => {
      return {
        component: RecoveryPWDContainer,
        restProps: { config, companyDomains, companyDomainSelected },
        isAllowedRoute: true,
        redirectTo: "/",
      };
    }
  )(ProtectedRoute)
);

export default RecoveryPWDRoute;
