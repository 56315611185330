// @flow
import { createAction } from "redux-actions";
// Actions for USERS

// Sign Up process:
// Check Invitation
export const USERS_SIGN_UP_CHECK_INVITATION: string =
  "USERS/SIGN/UP/CHECK/INVITATION";
export const USERS_SIGN_UP_CHECK_INVITATION_REQUEST: string =
  "USERS/SIGN/UP/CHECK/INVITATION/REQUEST";
export const USERS_SIGN_UP_CHECK_INVITATION_SUCCESS: string =
  "USERS/SIGN/UP/CHECK/INVITATION/SUCCESS";
export const USERS_SIGN_UP_CHECK_INVITATION_FAILURE: string =
  "USERS/SIGN/UP/CHECK/INVITATION/FAILURE";
export const usersSignUpCheckInvitation = createAction(
  USERS_SIGN_UP_CHECK_INVITATION
);
export const usersSignUpCheckInvitationRequest = createAction(
  USERS_SIGN_UP_CHECK_INVITATION_REQUEST
);
export const usersSignUpCheckInvitationSuccess = createAction(
  USERS_SIGN_UP_CHECK_INVITATION_SUCCESS
);
export const usersSignUpCheckInvitationFailure = createAction(
  USERS_SIGN_UP_CHECK_INVITATION_FAILURE
);
// Set Account (save account information to send at end process)
export const USERS_SIGN_UP_SET_ACCOUNT: string = "USERS/SIGN/UP/SET/ACCOUNT";
export const usersSignUpSetAccount = createAction(USERS_SIGN_UP_SET_ACCOUNT);
// Sign up process success
export const USERS_SIGN_UP_SUCCESS: string = "USERS/SIGN/UP/SUCCESS";
export const usersSignUpSuccess = createAction(USERS_SIGN_UP_SUCCESS);
// Sign up process done
export const USERS_SIGN_UP_DONE: string = "USERS/SIGN/UP/DONE";
export const usersSignUpDone = createAction(USERS_SIGN_UP_DONE);
// Sign Up END />
