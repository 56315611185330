// @flow
import { put, select, call, all, fork, takeLatest } from "redux-saga/effects";
import ContactApi from "../api/Contact";
import { Contact } from "../actions/actions";

//Contact Form for users

function* doContactForm(action) {
  yield put(Contact.contactFormRequest());
  window.logger.log("doContactForm", action);
  const { body, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to send contact form
    yield call(ContactApi.Form, api, body);
    yield put(Contact.contactFormSuccess());
    callbackSuccess();
  } catch (error) {
    window.logger.log("ErrorSaga", error);
    yield put(Contact.contactFormFailure());
    callbackError(error);
  }
}

export default function* contactSagas(): Generator<*, *, *> {
  yield all([fork(takeLatest, Contact.CONTACT_FORM, doContactForm)]);
}
