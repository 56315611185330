// @flow
//Logger for different type messages.
export const logger = () => {
  let logger = {
    log: () => {},
    dir: () => {},
    info: () => {},
    error: () => {},
  };
  if (
    process.env.REACT_APP_ENV === "dev" ||
    process.env.REACT_APP_ENV === "test"
  ) {
    logger = {
      log: (...args: Array<mixed>) => console.log(...args),
      dir: (...args: Array<mixed>) => console.dir(...args),
      info: (...args: Array<mixed>) => console.info(...args),
      error: (...args: Array<mixed>) => console.error(...args),
    };
  }
  window.logger = logger;
};
