// @flow
import { createAction } from "redux-actions";

// Actions for AUTH (authorized user after sign in successfully)

//Sign in
export const AUTH_FETCH_ACCOUNTS: string = "AUTH/FETCH/ACCOUNTS";
export const AUTH_FETCH_ACCOUNTS_REQUEST: string =
  "AUTH/FETCH/ACCOUNTS/REQUEST";
export const AUTH_FETCH_ACCOUNTS_SUCCESS: string =
  "AUTH/FETCH/ACCOUNTS/SUCCESS";
export const AUTH_FETCH_ACCOUNTS_FAILURE: string =
  "AUTH/FETCH/ACCOUNTS/FAILURE";
export const authFetchAccounts = createAction(AUTH_FETCH_ACCOUNTS);
export const authFetchAccountsRequest = createAction(
  AUTH_FETCH_ACCOUNTS_REQUEST
);
export const authFetchAccountsSuccess = createAction(
  AUTH_FETCH_ACCOUNTS_SUCCESS
);
export const authFetchAccountsFailure = createAction(
  AUTH_FETCH_ACCOUNTS_FAILURE
);

//Sign in
export const AUTH_SIGNIN: string = "AUTH/SIGNIN";
export const AUTH_SIGNIN_REQUEST: string = "AUTH/SIGNIN/REQUEST";
export const AUTH_SIGNIN_SUCCESS: string = "AUTH/SIGNIN/SUCCESS";
export const AUTH_SIGNIN_FAILURE: string = "AUTH/SIGNIN/FAILURE";
export const authSignIn = createAction(AUTH_SIGNIN);
export const authSignInRequest = createAction(AUTH_SIGNIN_REQUEST);
export const authSignInSuccess = createAction(AUTH_SIGNIN_SUCCESS);
export const authSignInFailure = createAction(AUTH_SIGNIN_FAILURE);

//Sign up
export const AUTH_SIGNUP: string = "AUTH/SIGNUP";
export const AUTH_SIGNUP_REQUEST: string = "AUTH/SIGNUP/REQUEST";
export const AUTH_SIGNUP_SUCCESS: string = "AUTH/SIGNUP/SUCCESS";
export const AUTH_SIGNUP_FAILURE: string = "AUTH/SIGNUP/FAILURE";
export const authSignUp = createAction(AUTH_SIGNUP);
export const authSignUpRequest = createAction(AUTH_SIGNUP_REQUEST);
export const authSignUpSuccess = createAction(AUTH_SIGNUP_SUCCESS);
export const authSignUpFailure = createAction(AUTH_SIGNUP_FAILURE);

//Activation Account
export const AUTH_ACTIVATE_ACCOUNT: string = "AUTH/ACTIVATE/ACCOUNT";
export const AUTH_ACTIVATE_ACCOUNT_REQUEST: string =
  "AUTH/ACTIVATE/ACCOUNT/REQUEST";
export const AUTH_ACTIVATE_ACCOUNT_SUCCESS: string =
  "AUTH/ACTIVATE/ACCOUNT/SUCCESS";
export const AUTH_ACTIVATE_ACCOUNT_FAILURE: string =
  "AUTH/ACTIVATE/ACCOUNT/FAILURE";
export const authActivateAccount = createAction(AUTH_ACTIVATE_ACCOUNT);
export const authActivateAccountRequest = createAction(
  AUTH_ACTIVATE_ACCOUNT_REQUEST
);
export const authActivateAccountSuccess = createAction(
  AUTH_ACTIVATE_ACCOUNT_SUCCESS
);
export const authActivateAccountFailure = createAction(
  AUTH_ACTIVATE_ACCOUNT_FAILURE
);

//Recovery Password
export const AUTH_RECOVERY_PWD: string = "AUTH/RECOVERY/PWD";
export const AUTH_RECOVERY_PWD_REQUEST: string = "AUTH/RECOVERY/PWD/REQUEST";
export const AUTH_RECOVERY_PWD_SUCCESS: string = "AUTH/RECOVERY/PWD/SUCCESS";
export const AUTH_RECOVERY_PWD_FAILURE: string = "AUTH/RECOVERY/PWD/FAILURE";
export const authRecoveryPWD = createAction(AUTH_RECOVERY_PWD);
export const authRecoveryPWDRequest = createAction(AUTH_RECOVERY_PWD_REQUEST);
export const authRecoveryPWDSuccess = createAction(AUTH_RECOVERY_PWD_SUCCESS);
export const authRecoveryPWDFailure = createAction(AUTH_RECOVERY_PWD_FAILURE);

//Reset Password
export const AUTH_RESET_PWD: string = "AUTH/RESET/PWD";
export const AUTH_RESET_PWD_REQUEST: string = "AUTH/RESET/PWD/REQUEST";
export const AUTH_RESET_PWD_SUCCESS: string = "AUTH/RESET/PWD/SUCCESS";
export const AUTH_RESET_PWD_FAILURE: string = "AUTH/RESET/PWD/FAILURE";
export const authResetPWD = createAction(AUTH_RESET_PWD);
export const authResetPWDRequest = createAction(AUTH_RESET_PWD_REQUEST);
export const authResetPWDSuccess = createAction(AUTH_RESET_PWD_SUCCESS);
export const authResetPWDFailure = createAction(AUTH_RESET_PWD_FAILURE);
