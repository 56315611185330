// @flow
//Heto reducers
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as reduxFormReducer } from "redux-form";
import serviceWorker from "./serviceWorker";
import routerBeforeTransitions from "./routerBeforeTransitions";
import api from "./api";
import config from "./config";
import localStore from "./localStore";
import company from "./company";
import auth from "./auth";
import users from "./users";

// main reducers
export const reducers: any = combineReducers({
  serviceWorker,
  router: routerReducer,
  routerBeforeTransitions,
  form: reduxFormReducer,
  // our reducers here
  api,
  config,
  localStore,
  company,
  auth,
  users,
});
