// @flow
// CONFIG Reducer
import AppConfig from "../config/";
type State = {
  +origin: string,
  +protocol: string,
  +hostname: string,
  +port: string,
  +suffix: string,
  +baseDomain: string,
  +isMobileBrowser: boolean,
  +isTouchableDevice: boolean,
  +websitename: string,
  +version: string,
  +appEnv: string,
  +api: {
    +protocol: string,
    +tenant: string,
    +base: string,
    +version: string,
  },
};

export default function config(state: State = AppConfig()): State {
  return state;
}
