// @flow
import * as React from "react";
import Breadcrumbs from "../shared/Breadcrumbs";
import Newsletter from "../shared/Newsletter";
import PreFooter from "../shared/PreFooter";
import CabanaaLogo from "../../images/caba-logo.svg";

type Props = {};

class AboutPresentational extends React.Component<Props> {
  render() {
    return [
      <Breadcrumbs
        key="breadcrumbs-about"
        crumbs={[
          { pathName: "Home", path: "/" },
          { pathName: "About Us", path: null },
        ]}
      />,
      <div
        key="content-about"
        className="container-fluid body nopadding website-about"
      >
        <section className="about text-center">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <h1 className="text-teal website">About Us</h1>
              <div className="row justify-content-center">
                <div className="col-sm-1 col-xs-5 barseparator mt5" />
              </div>
              <h4 className="website">Our Story</h4>
            </div>
          </div>
        </section>

        <section className="company">
          <div className="row">
            <div className="col-sm-12">
              <div className="companycontainer">
                <div className="logo">
                  <img src={CabanaaLogo} alt="cabaana-logo" />
                </div>
                <div className="body">
                  <h4>The Company</h4>
                  <span>
                    <a
                      href="https://www.cabaana.com/"
                      target="_blank"
                      rel="noopener noreferrer license author"
                    >
                      Cabaana Inc.
                    </a>{" "}
                    is a technology company, headquartered in Michigan, USA,
                    specializing in consulting and application development.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whoweare text-center">
          <div className="row">
            <div className="col-sm-12">
              <div className="teamcontainer">
                <div className="body">
                  <h4>Who are we?</h4>
                  <span>
                    We are a passionate team of engineers, analysts, designers,
                    and developers driven by solving complex business problems
                    in the corporate and health science education sectors.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="background about-us"></section>

        <section className="ourmission text-center mb100">
          <div className="row">
            <div className="col-sm-12">
              <div className="missioncontainer">
                <div className="body">
                  <h4>Our Mission</h4>
                  <span>
                    To be the bridge between customers and changing
                    technologies, creating a clear pathway for success.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Newsletter />
        <PreFooter />
      </div>,
    ];
  }
}

export default AboutPresentational;
