// @flow
import { createAction } from "redux-actions";

// Actions for API
export const SERVICE_WORKER_INIT: string = "SERVICE_WORKER/INIT";
export const serviceWorkerInit = createAction(SERVICE_WORKER_INIT);

export const SERVICE_WORKER_REFRESH_BUTTON_SHOW: string =
  "SERVICE_WORKER/REFRESH/BUTTON_SHOW";
export const serviceWorkerRefreshButtonShow = createAction(
  SERVICE_WORKER_REFRESH_BUTTON_SHOW
);
