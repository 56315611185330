// @flow
// Import Features
import * as React from "react";
// Import Components
import { Header, Icon, Image, Message, Modal } from "semantic-ui-react";
import ErrorComponent from "../shared/ErrorComponent";
// Import images
import waitingForProcess from "../../images/waiting-for-process.svg";
import msgSuccess from "../../images/message-success.svg";
// Types
type Props = {
  errorDownloadFile: string,
  fileName: string,
  from: "ios" | "android",
  status: "downloading" | "success" | "error" | "done",
};
class DownloadFilePresentational extends React.Component<Props> {
  render() {
    const { errorDownloadFile, fileName, from, status } = this.props;
    return (
      <div id="main" className="activate-account">
        <Modal
          className="modal-create-company modal-activation-account seriously-fullscreen-mobile"
          dimmer={false}
          open={true}
          size={"small"}
        >
          <Modal.Header>
            <Modal.Header>
              {status === "downloading" && <h4>Downloading document ...</h4>}
              {status === "success" && (
                <React.Fragment>
                  <h4 style={{ color: "#208A19" }}>
                    Downloading the document has been successfully
                  </h4>
                  {from === "android" && (
                    <Header as="h2" color="blue">
                      If the document has not been opened by default, check your
                      notifications to see this document downloaded
                    </Header>
                  )}
                </React.Fragment>
              )}
              {status === "error" && (
                <h4 style={{ color: "#a94442" }}>
                  Downloading the document has not been successfully
                </h4>
              )}
            </Modal.Header>
          </Modal.Header>
          {status === "downloading" && (
            <Modal.Content>
              <Image
                src={waitingForProcess}
                className="illustration mb50 mt50"
                alt="illustration-empty"
                centered
              />
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  Downloading {fileName}.
                </Message.Content>
              </Message>
            </Modal.Content>
          )}
          {status === "success" && (
            <Modal.Content>
              <Image
                src={msgSuccess}
                className="illustration mb50 mt50"
                alt="illustration-empty"
                centered
              />
              <Message icon positive>
                <Icon name="inbox" />
                <Message.Content>
                  <Message.Header>Success</Message.Header>
                  Downloaded{" "}
                  <strong className="text-success">{fileName}</strong>
                </Message.Content>
              </Message>
            </Modal.Content>
          )}
          {status === "error" && (
            <Modal.Content>
              {errorDownloadFile && (
                <ErrorComponent
                  className="activation-account-error"
                  errorMessageHeader="Error"
                  errorMessageContent={errorDownloadFile}
                />
              )}
            </Modal.Content>
          )}
        </Modal>
      </div>
    );
  }
}
export default DownloadFilePresentational;
