// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
import * as React from "react";
import { Field } from "redux-form";
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Image,
  Input,
  Message,
} from "semantic-ui-react";
import Breadcrumbs from "../shared/Breadcrumbs";
import Newsletter from "../shared/Newsletter";
import PreFooter from "../shared/PreFooter";
import iconX from "../../images/x.svg";

const renderInputField: React.ComponentType<*> = ({
  input,
  isloading,
  placeholder,
  type,
  className,
  meta: { active, error },
  onReset,
}) => (
  <Form.Field>
    <Form.Input
      {...input}
      icon={
        input.value.length > 0 &&
        (isloading ? (
          <Icon loading name="spinner" />
        ) : (
          <Image src={iconX} alt="x" onClick={onReset} />
        ))
      }
      className={
        !active && input.value.length > 0 && error ? "warning" : className
      }
      fluid
      placeholder={placeholder}
      type={type}
      error={!active && input.value.length > 0 && error && true}
    />
  </Form.Field>
);

type Props = {
  companyDomains: Array<string>,
  companyDomain: string,
  companyDomainNotFound: boolean,
  onCompanyDomainToAdd: (event: Object, { value: string }) => void,
  onRemoveCompanyDomain: (companyToDelete: string) => (event: Object) => void,
  baseDomain: string,
  suffix: string,
  errorSuccess: string,
  error: string,
  submitting: boolean,
  valid: boolean,
  onSetCompanyDomain: (event: Object, { field: string, value: string }) => void,
  onSubmit: Function,
  reset: Function,
  goToSignIn: Function,
  isFetchingAccounts: boolean,
  accountsAvailable: Array<Object>,
  accountSelected: number,
  onSetAccount: (event: Object, { value: number }) => void,
};

const parseName = (name: string) => name.split(/(?=[A-Z])/).join(" ");
const parseApiName = (apiName: string) =>
  apiName.replace(
    /(Company|Companies|Institution|Institutions|Administrators|Faculties|File|Files)/gi,
    (str: string) => {
      switch (str) {
        case "Company":
          return "School";
        case "Companies":
          return "Schools";
        case "Institution":
          return "Clinic";
        case "Institutions":
          return "Clinics";
        case "Administrators":
          return "Admins";
        case "Faculties":
          return "Faculty";
        case "Files":
          return "Documents";
        case "File":
          return "Document";
        default:
          return str;
      }
    }
  );

class RecoveryPWDPresentational extends React.Component<Props> {
  render() {
    const {
      companyDomainNotFound,
      onSubmit,
      errorSuccess,
      error,
      submitting,
      valid,
      reset,
      goToSignIn,
      isFetchingAccounts,
    } = this.props;
    return [
      <Breadcrumbs
        key="breadcrumbs-recovery-pwd"
        crumbs={[
          { pathName: "Home", path: "/" },
          { pathName: "Sign In", path: "/signin" },
          { pathName: "Recover Password", path: null },
        ]}
      />,
      <div
        key="content-recovery-pwd"
        className="container-fluid body website-recovery-pwd"
      >
        <section className="input-form mb100">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
              <h1 className="title text-teal text-center website">
                Recover your Password
              </h1>
              <div className="subtitle text-center">
                Please introduce your email id
              </div>
              <div className="form mt30 modal-create-company signin seriously-fullscreen-mobile">
                <Form
                  loading={submitting}
                  onSubmit={onSubmit}
                  success={errorSuccess === "success" && !error && !submitting}
                  error={errorSuccess === "error" && error && !submitting}
                >
                  {this.renderFieldCompanyDomain()}
                  <Field
                    name="email"
                    type="email"
                    placeholder="Email"
                    isloading={isFetchingAccounts}
                    component={renderInputField}
                    className={valid ? "success" : ""}
                    onReset={reset}
                  />
                  {this.renderFieldAccountSelected()}
                  <Button
                    loading={submitting}
                    disabled={!valid || submitting}
                    fluid
                    type="submit"
                    className={`${valid ? "skejule" : ""} text-center mt25`}
                  >
                    Recover Password
                  </Button>
                  <div className="message mb20">
                    <span className="forgotpwd mr10">
                      <a style={{ cursor: "pointer" }} onClick={goToSignIn}>
                        Cancel
                      </a>
                    </span>
                  </div>
                  {companyDomainNotFound && (
                    <div className="message-board mt30">
                      <Message negative style={{ padding: "7px 32px" }}>
                        <span className="header">
                          This School does not exist
                        </span>
                      </Message>
                    </div>
                  )}
                  <div className="message-board">
                    <Message
                      error
                      negative
                      style={{ paddingTop: "7px", paddingLeft: "35px" }}
                    >
                      <span className="header">Error</span>
                      {error && (
                        <span className="content">
                          <br />
                          {error}
                        </span>
                      )}
                    </Message>
                    <Message
                      success
                      style={{ paddingTop: "7px", paddingLeft: "35px" }}
                    >
                      <span className="header">Completed</span>
                      <span className="content">
                        <br />
                        We've just sent an email with instructions to reset your
                        email. Please check your inbox.
                      </span>
                    </Message>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
        <Newsletter />
        <PreFooter />
      </div>,
    ];
  }
  renderFieldCompanyDomain = () => {
    const {
      baseDomain,
      suffix,
      companyDomains,
      companyDomain,
      onSetCompanyDomain,
      onCompanyDomainToAdd,
      onRemoveCompanyDomain,
    } = this.props;
    if (companyDomains.length > 0) {
      return (
        <div
          field="companyDomain"
          className="ui fluid right labeled input skejule nofloat info"
        >
          <Dropdown
            name="add"
            maxLength="100"
            selectOnBlur={false}
            selection
            search
            fluid
            icon={null}
            options={companyDomains.map((value, index) => ({
              name: "remove",
              key: value + index,
              value: value,
              text: value,
              icon: (
                <Icon
                  name="trash"
                  className="right"
                  onClick={onRemoveCompanyDomain(value)}
                />
              ),
            }))}
            text={companyDomain || "Select School"}
            placeholder="Select School"
            value={companyDomain}
            allowAdditions
            additionLabel={<i className="skejule-teal">Add School: </i>}
            noResultsMessage={
              companyDomains.length === 0 ? "Empty, add ..." : "Not Found"
            }
            onAddItem={onCompanyDomainToAdd}
            onChange={onSetCompanyDomain}
          />
          <div className="ui label label">{`.${baseDomain}${suffix}`}</div>
        </div>
      );
    }
    return (
      <Input
        fluid
        placeholder="School"
        label={`.${baseDomain}${suffix}`}
        labelPosition="right"
        className={"skejule nofloat info"}
        field="companyDomain"
        value={companyDomain}
        onChange={onSetCompanyDomain}
      />
    );
  };
  renderFieldAccountSelected = () => {
    const {
      isFetchingAccounts,
      accountsAvailable,
      accountSelected,
      onSetAccount,
    } = this.props;
    if (accountsAvailable.length > 1) {
      return (
        <div field="accountSelected" className="ui fluid input skejule">
          <Dropdown
            selectOnBlur={false}
            selection
            fluid
            loading={isFetchingAccounts}
            options={accountsAvailable.map(({ role, institution }, index) => ({
              key: role + index,
              value: index,
              text: `${parseApiName(parseName(role))} ${
                institution ? "in " + institution.name : ""
              }`,
            }))}
            text={
              accountSelected >= 0
                ? `${parseApiName(
                    parseName(accountsAvailable[accountSelected].role)
                  )} ${
                    accountsAvailable[accountSelected].institution
                      ? "in " +
                        accountsAvailable[accountSelected].institution.name
                      : ""
                  }`
                : "Select Account"
            }
            placeholder="Select Account"
            onChange={onSetAccount}
            value={accountSelected}
          />
        </div>
      );
    }
    return null;
  };
}

export default RecoveryPWDPresentational;
