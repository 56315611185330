// @flow
import * as React from "react";
import { Link, Element } from "react-scroll";
import { Container, Grid, List, Tab } from "semantic-ui-react";
import Breadcrumbs from "../shared/Breadcrumbs";
import Newsletter from "../shared/Newsletter";
import PreFooter from "../shared/PreFooter";

type Props = {};
type State = {
  panes: Array<{
    menuItem: string,
    name: string,
  }>,
  selectedLegal: number,
};

class LegalPresentational extends React.Component<Props, State> {
  state = {
    panes: [
      { menuItem: "Terms of Service", name: "TermsOfService" },
      { menuItem: "Privacy", name: "Privacy" },
      { menuItem: "Security", name: "Security" },
    ],
    selectedLegal: 0,
  };
  onSelectedLegal = (event: Object, { activeIndex }: { activeIndex: number }) =>
    this.setState({ selectedLegal: activeIndex });
  renderTermsOfService = () => {
    return (
      <Container className="mt10 mb20">
        <div className="subtitle mb10">
          <h5>1. Acceptance of Terms and Conditions; Charges</h5>
        </div>
        <div className="text-justify">
          By accessing, downloading and/or using the Heto, you acknowledge
          having read and understood, and you hereby agree, to be bound by and
          comply with these terms. If you do not agree to these terms, you must
          not install or use the Heto application. Heto application is protected
          by law, including, but not limited to, the copyright laws of the
          United States and other countries as well as other laws protecting
          intellectual property, and international treaties. Heto application is
          controlled and made available by Cabaana Inc. from its offices within
          the State of Michigan, United States.
          <p className="mt10 mb10" />
          You may incur network or data charges, roaming charges or other costs
          or fees when you download or use the Heto application or certain of
          its features, such as location-based services like user location
          (“Network Charges”). Cabaana Inc. has no liability to you for any of
          Network Charges.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>2. License Grant</h5>
        </div>
        <div className="text-justify">
          The term “Heto” or “Heto application” includes the web based & mobile
          applications and computer programs (software) that you access and/or
          download, and associated data and information (including any user
          guide, updates and revisions) available to you. With limited
          exceptions with respect to software or elements of the Heto
          application that Cabaana Inc. has licensed from third parties, the
          Heto application is owned by Cabaana Inc.
          <p className="mt10 mb10" />
          The Heto application is licensed for the subscription period (
          <Link
            style={{ cursor: "pointer" }}
            to="myScrollLegalubscription"
            smooth={true}
            duration={250}
            containerId="containerLegal"
            className="skejule-teal"
          >
            15
          </Link>
          ), not sold, to you. Cabaana Inc. considers the Heto application to be
          its confidential and proprietary information.
          <p className="mt10 mb10" />
          Subject to the terms of this agreement, Cabaana Inc. grants you a
          non-exclusive license to use the Heto application solely as described
          in this Agreement, and solely on the Platforms for which the Heto
          application was specifically designed by Cabaana Inc. Except as
          expressly stated herein, no other rights are granted to you by
          implication, estoppel, or otherwise, under any patent, copyright,
          trade secret, trademark, or other intellectual property right of
          Cabaana Inc. or any of its licensors.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>3. License Limitations</h5>
        </div>
        <div className="text-justify">
          You are authorized to use the Heto application during your
          subscription period (
          <Link
            style={{ cursor: "pointer" }}
            to="myScrollLegalubscription"
            smooth={true}
            duration={250}
            containerId="containerLegal"
            className="skejule-teal"
          >
            15
          </Link>
          ), solely an individual for the limited purpose of determining and
          selecting applicable Cabaana Inc. Products. “Cabaana Inc. Products”
          means products or sold or marketed under any Cabaana Inc. label.
          <p className="mt10 mb10" />
          You must not copy, modify, transfer, rent, lease, sublicense, assign,
          reverse assemble, reverse compile or create derivative works of the
          Heto application; nor may you disclose the Heto application to third
          parties, nor may you access, download or use the Heto application in
          any environment or any platform other than the Platforms for which it
          was designed. You must not attempt to derive any confidential
          information, processes, code or algorithms from the Heto application.
          To the extent any of the foregoing restrictions on reverse
          engineering, decompiling or other use of the Heto application are not
          enforceable under applicable law, then the restriction will apply to
          the greatest extent consistent with applicable law. Further, you will
          not engage in such activities unless you first give Cabaana Inc.
          advanced written notice of your intended action, and you agree to
          allow Cabaana Inc. to provide you any relevant assistance or
          information that might obviate the need for such action. Cabaana Inc.
          reserves the right to modify the Heto application and related data and
          information at any time, and to change its functionality. You
          acknowledge and agree that Cabaana Inc. retains sole ownership of all
          intellectual property rights in the Heto application, Cabaana Inc.
          Products and related information, including but not limited to patent,
          copyright, trade dress, trade secret, and trademark rights.
        </div>

        <div className="subtitle mt10 mb10">
          <h5>4. Termination of License Rights</h5>
        </div>
        <div className="text-justify">
          This Agreement and the license granted to you will terminate
          automatically upon the end of your subscription period (
          <Link
            style={{ cursor: "pointer" }}
            to="myScrollLegalubscription"
            smooth={true}
            duration={250}
            containerId="containerLegal"
            className="skejule-teal"
          >
            Section 15
          </Link>
          ) or upon the breach of this Agreement. Cabaana Inc. may terminate
          your license rights, and/or may stop offering the Heto application,
          and/or information without notice and without any liability, if any
          laws or rights are violated resulting from misuse of the mobile app.
          Upon any termination, you agree to cease all use of the Heto
          application and to remove the Heto application from the Mobile
          Platform(s).
        </div>
        <div className="subtitle mt10 mb10">
          <h5>5. Protection of Confidential Information</h5>
        </div>
        <div className="text-justify">
          You agree to hold all Confidential Information in confidence
          indefinitely. "Confidential Information" shall mean the Heto
          application and any and all information, technology, algorithms, code,
          data, specifications, designs, constructions, techniques, formulae,
          materials, and manufacturing processes of Cabaana Inc. or concerning
          Cabaana Inc. Products, except information that is: published or
          otherwise becomes part of the public domain through no act or omission
          by you; received by you from a third party who is legally in
          possession of the information and not under an obligation of
          confidentiality with respect the information; or, was already in your
          possession, with no duty of confidentiality attached thereto, prior to
          its receipt from Cabaana Inc., as evidenced by written records. This
          obligation of confidentiality shall survive termination, cancellation,
          or expiration of this License Agreement.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>6. Trademarks and Copyrights</h5>
        </div>
        <div className="text-justify">
          The trademarks, service marks, and logos (the "Trademarks") used and
          displayed in the Heto application are registered and unregistered
          Trademarks of Cabaana Inc. and others. Nothing in this Agreement or
          the Heto application should be construed as granting, by implication,
          estoppel, or otherwise, any license or right to use any Trademark
          without the written permission of the Trademark owner. Trademarks of
          Cabaana Inc. may not be used in any way without prior written
          permission of Cabaana Inc. The copyrights in the Heto application and
          all data and information provided in connection with the Heto
          application are held by Cabaana Inc. or by the original creator.
          Except as stated herein, none of the Heto application, data or
          information may be copied, reproduced, distributed, republished,
          downloaded, displayed, posted or transmitted in any form or by any
          means, including, but not limited to, electronic, mechanical,
          photocopying, recording, or otherwise, without the prior written
          permission of Cabaana Inc. or the copyright owner.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>7. Links To Third Party Sites</h5>
        </div>
        <div className="text-justify">
          The Heto application may contain links to third-party sites. Any
          linked sites are not under the control of Cabaana Inc., and Cabaana
          Inc. is not responsible for the contents of any linked site, any link
          contained in a linked site, any changes or updates to such linked
          sites, or any transmission from any linked site. Cabaana Inc. is
          providing these links to third-party sites only as a convenience, and
          the inclusion of any link does not imply endorsement by Cabaana Inc.
          of the site.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>8. Limitation of Liability</h5>
        </div>
        <div className="text-justify">
          In no event, shall Cabaana Inc. be liable to the licensee for any
          direct, indirect, special, punitive, incidental or consequential
          damages or lost profits arising out of or related to this agreement,
          Heto application or related data & information.
          <p className="mt10 mb10" />
          In no event, shall Cabaana Inc. be liable to the licensee for any
          damages resulting from inaccuracy of the data or information, and/or
          claims regarding infringement of intellectual property rights, tort or
          negligence.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>9. Certain Third Parties and Additional Terms</h5>
        </div>
        <div className="text-justify">
          The company or companies that make the Mobile Platform available to
          you (e.g., Apple, Inc., Google, Inc., etc.) are made express third
          party beneficiaries (“TPBs”) to the terms of this Agreement and all
          limitations and disclaimers provided herein. By downloading,
          installing or using the Heto application, you acknowledge and agree
          that none of the TPBs has liability to you under any theory, including
          contract, tort, strict liability or otherwise, for your downloading,
          installation or use of the Heto application or any data that you
          receive from or provide through the Heto application.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>10. Cabaana Inc. Products</h5>
        </div>
        <div className="text-justify">
          Cabaana Inc.’ obligations and responsibilities regarding Cabaana Inc.
          Products or services are governed solely by the agreements under which
          they are sold or licensed. Cabaana Inc. Products orders are subject to
          acceptance or rejection by Cabaana Inc.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>11. Privacy and Data Collection</h5>
        </div>
        <div className="text-justify">
          The Heto application, by nature of its function, collects information
          only to be displayed on devices of your users, authorized by your
          organization. Such information you provide about your students,
          faculty members, etc. include names, email addresses, phone numbers,
          etc. The Heto application will collect crash reports and app errors,
          which can be sent to Cabaana at the users’ discretion, when requested
          by a support team member.
          <p className="mt10 mb10" />
          Cabaana Inc. does not have access to the information collected as part
          of the Heto application installed on your user mobile devices and the
          Heto application is not designed to share the information with any
          third parties.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>12. Security</h5>
        </div>
        <div className="text-justify">
          The Heto application has security measures built in to protect against
          the unauthorized use, loss, misuse and/or alteration of the Heto
          application and related data and information. You may not make any
          effort to circumvent these measures.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>13. Governing Law</h5>
        </div>
        <div className="text-justify">
          This Agreement and use of the Heto application will be governed by the
          appropriate software laws in the Unites States of America. All parties
          will submit to the jurisdiction of either the District Courts or
          Supreme Court within the USA.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>14. Entire Agreement; Updates</h5>
        </div>
        <div className="text-justify">
          This Agreement contains the entire agreement of the parties with
          respect to the Heto application. It supersedes all prior
          understandings, writings, proposals, representations or
          communications, oral or written, of either party. Cabaana Inc.
          reserves the right to update and/or change the terms and conditions of
          this Agreement at any time. Cabaana Inc. will make updated and/or
          changed terms and conditions available to you by sending them or a
          notice to you, posting them on Cabaana Inc.’ website, and/or providing
          them as a new click-through Agreement that must be accepted before
          continued use of the Heto application is permitted. Your continued use
          of the Heto application constitutes your agreement to the updated
          and/or changed terms and conditions. If you do not agree to the
          updated and/or changed terms and conditions, you will not be able to
          continue use of the Heto application.
        </div>
        <Element className="subtitle mt10 mb10" name="myScrollLegalubscription">
          <h5>15. Subscription Period and Payment</h5>
        </Element>
        <div className="text-justify">
          As part of this agreement, the client agrees to use Heto for a minimum
          period agreed upon and as specified in the contract to be signed
          between Cabaana Inc. and the client. years. All subscription charges,
          and changes will be honored as specified in the contract.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>16. Contact</h5>
        </div>
        <div className="text-justify">
          If you have any questions about the Heto application or this
          Agreement, you can contact us at{" "}
          <a href="mailto:heto@cabaana.com" target="_top">
            <span className="skejule-teal">heto@cabaana.com</span>
          </a>
          .
        </div>
      </Container>
    );
  };
  renderPrivacy = () => {
    return (
      <Container className="mt10 mb20">
        <div className="text-justify">
          Please read this and make sure you understand our privacy principles
          well:
          <p className="mt10 mb10" />
          <List bulleted>
            <List.Item>
              Anything you post to Heto is private to your institution. That is,
              viewing the messages, pictures and documents shared within the
              application requires authentication as a user of the institution.
            </List.Item>
            <List.Item>
              Heto is the custodian of data on behalf of the
              schools/institutions that use Heto. We don't own any data.
              Institutions own their data.
            </List.Item>
            <List.Item>
              We believe that more transparency is better than less. We try to
              make our product easy to use, with settings and options that are
              easy to find and understand. This is good for privacy, good for
              the product, and good for Heto customers and users.
            </List.Item>
            <List.Item>
              Institutions can select their own data retention policies
              (depending on their level of service. Administrators and owners
              can change these settings but we want you to know where we stand.
            </List.Item>
            <List.Item>
              Privacy goes along with security and confidentiality. They are all
              equally very important to us and we at Heto take them very
              seriously.
            </List.Item>
          </List>
          Heto Privacy Policy
          <p className="mt10 mb10" />
          Updated: June 1, 2018
          <p className="mt10 mb10" />
          This privacy policy is here to help you understand what information we
          collect at Heto, how we use it, and what choices you have. When we
          talk about Heto in this policy, we are talking about Cabaana Inc.
          Inc., the company, Heto, the downloadable application, and the Heto
          website at www.hetoonline.com. Heto is available for use via a web
          browser or applications specific to your desktop or mobile device.
          <p className="mt10 mb10" />
          This policy describes how Heto treats your information, not how other
          organizations treat your information. If you are using Heto in a
          workplace or on a device or account issued to you by your employer or
          another organization, that company or organization likely has its own
          policies regarding storage, access, modification, deletion, and
          retention of communications and content which may apply to your use of
          Heto. Content that would otherwise be considered private to you or to
          a limited group of people may, in some cases, be accessible by your
          Institution owner or administrator. Please check with your employer,
          Institution owner or administrator about the policies it has in place
          regarding your communications and related content on Heto.
          <p className="mt10 mb10" />
          More on this below.
          <p className="mt10 mb10" />
          In this policy we talk about various roles within Heto and the
          privileges that come with each. It's helpful to understand these roles
          and the relationships between them. Institution Administrator >
          Institution Manager > Institution Member. All Institution
          Administrators are Institution Managers and all Institution
          Administrators and Institution Managers are also Institution Members.
          <p className="mt10 mb10" />
          Information we collect and receive
          <p className="mt10 mb10" />
          We collect different kinds of information. Some of it is personally
          identifiable and some is non-identifying or aggregated. Here are the
          types of information we collect or receive:
          <p className="mt10 mb10" />
          <List bulleted>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                Institution information.
              </span>{" "}
              When you create a Institution on Heto, we collect your email
              address (as the Institution Administrator), your Institution name,
              Heto domain (ex: your-Institution -name.hetoonline.com), and
              password.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                Account and profile information.
              </span>{" "}
              The only information we require to create your Heto account is an
              email address and password. Optional information you can enter
              into your profile includes information such as your first and last
              name, what you do, skills, interests, contact details, social
              media profiles, etc. Your Institution Administrator(s) may request
              you to provide additional information about yourself in your
              profile, and Heto has no control over such additional information
              collected. Any information you add to your profile is visible to
              other people on your Institution as described on your profile
              management page.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>Billing information.</span>{" "}
              If you purchase a paid version of Heto, our third party payment
              processors will collect and store your billing address and credit
              card information.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>Login data.</span> When you
              use Heto, our servers automatically record information, including
              information that your browser sends whenever you visit a website
              or your mobile app sends when you're using it. This log data may
              include your Internet Protocol address, the address of the web
              page you visited before coming to Heto, your browser type and
              settings, the date and time of your request, information about
              your browser configuration and plug-ins, language preferences, and
              cookie data. Log data does not contain message content and is not
              routinely deleted.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>Device information.</span> In
              addition to log data, we may also collect information about the
              device you're using Heto on, including what type of device it is,
              what operating system you're using, device settings, unique device
              identifiers, and crash data. Whether we collect some or all of
              this information often depends on what type of device you're using
              and its settings.
              <p className="mt10 mb10" />
              <span style={{ fontWeight: "bold" }}>
                Geo-location information.
              </span>{" "}
              Precise GPS from mobile devices is collected only with your
              permission. WiFi and IP addresses received from your browser or
              device may be used to determine approximate location.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                Heto usage information.
              </span>{" "}
              This is information about which Institutions , channels, people,
              features, content, and links you interact with within Heto and
              what integrations with related services you use.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>Service integrations.</span>{" "}
              If you integrate with a third party service on Heto, we will
              connect that service to ours.
              <List.List>
                <List.Item>
                  We do not receive or store your passwords for any of these
                  services.
                </List.Item>
                <List.Item>
                  If you add an integration, the third party provider of the
                  integration may share certain information about your account
                  with Heto. Heto is not responsible for how institutions may
                  use and collect data through integrations.
                </List.Item>
                <List.Item>
                  An integration can be removed at any time. Removing an
                  integration unbinds that integration on a go-forward basis.
                  That does not, however, delete the content that was received
                  from the integration and indexed within Heto. That content
                  must be deleted manually.
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                Content that you send and receive within Heto.
              </span>{" "}
              This includes:
              <List.List>
                <List.Item>
                  This content can include messages, pictures, files and video
                  among other types of files.
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                Information from partners or other 3rd parties.
              </span>{" "}
              Heto may receive information from partners or others that we could
              use to make our own information better or more useful. This might
              be aggregate level information about which IP addresses go with
              which zip codes or it might be more specific information about how
              well an online marketing or email campaign performed.
            </List.Item>
          </List>
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Our Cookie Policy</h5>
        </div>
        <div className="text-justify">
          Heto uses cookies, or similar technologies like single-pixel gifs and
          web beacons, to record log data. We use both session-based and
          persistent cookies.
          <p className="mt10 mb10" />
          Cookies are small text files sent by us to your computer and from your
          computer to us, each time you visit our website. They are unique to
          your Heto account or your browser. Session-based cookies last only
          while your browser is open and are automatically deleted when you
          close your browser. Persistent cookies last until you or your browser
          delete them or until they expire.
          <p className="mt10 mb10" />
          Some cookies are associated with your Heto account and personal
          information in order to remember that you are logged in and which
          Institutions you are logged into. Other cookies are not tied to your
          Heto account but are unique and allow us to do site analytics and
          customization, among other similar things. If you access Heto through
          your browser, you can manage your cookie settings there but if you
          disable all cookies you may not be able to use Heto.
          <p className="mt10 mb10" />
          Heto sets and accesses our own cookies on our company-owned domains.
          In addition, we use 3rd parties like Google Analytics for website
          analytics. You may opt-out of third party cookies from 
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noopener noreferrer license author"
          >
            Google Analytics
          </a>
           on its respective website. We do not currently recognize or respond
          to browser-initiated Do Not Track signals as there is no consistent
          industry standard for compliance.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>How we use your information</h5>
        </div>
        <div className="text-justify">
          We use your information for the following:
          <p className="mt10 mb10" />
          <List bulleted>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                Providing the Heto service.
              </span>{" "}
              We use information you provide to authenticate you and deliver
              message content to you and from you.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                Understanding and improving our products.
              </span>{" "}
              To make the product better we have to understand how users are
              using it. We have a fair bit of data about usage and we intend to
              use it many different ways to improve our products, including
              research. This policy is not intended to place any limits on what
              we do with usage data that is aggregated or de-identified so it is
              no longer tied to a Heto user.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                Investigating and preventing bad stuff from happening.
              </span>{" "}
              We work hard to keep Hetosecure and to prevent abuse and fraud.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>Communicating with you</span>
              <List.List>
                <List.Item>
                  <span style={{ fontWeight: "bold" }}>
                    Solving your problems and responding to your requests.
                  </span>{" "}
                  If you contact us with a problem or question, we will use your
                  information to respond to that request and address your
                  problems or concerns.
                </List.Item>
                <List.Item>
                  <span style={{ fontWeight: "bold" }}>Email messages.</span> We
                  may send you service and administrative emails, such as when
                  we notice that you are nearing a message or integration limit.
                  We may also contact you to inform you about changes in our
                  services, our service offerings and important service related
                  notices, such as changes to this policy or security and fraud
                  notices. These messages are considered part of the service and
                  you may not opt-out of them. In addition, we sometimes send
                  emails to Heto users about new product features or other news
                  about Heto. You can opt-out of these at any time.
                </List.Item>
              </List.List>
            </List.Item>
          </List>
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Your choices</h5>
        </div>
        <div className="text-justify">
          When you use Heto, you have control over a number of things with
          respect to your own privacy and choices about how your content is
          visible to others or not. If you are a Heto institution Administrator,
          you have additional choices that impact your Institution 's privacy.
          Some users will not have access to all of the same choices that their
          Institution Administrator(s) or Institution That is because Heto is
          set up to be Institution oriented, and provides Institution owners
          with the maximum ability to control their Institutions.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Choices for Institution Administrators</h5>
        </div>
        <div className="text-justify">
          <List bulleted>
            <List.Item>
              Administrators have the ability to manage and change most of the
              Institution settings, Administrators can also deactivate member
              accounts for their Institution.
            </List.Item>
            <List.Item>
              Only a primary owner can deactivate or delete an Institution.
            </List.Item>
          </List>
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Other Choicess</h5>
        </div>
        <div className="text-justify">
          <List bulleted>
            <List.Item>
              The browser you use may provide you with the ability to control
              cookies or other types of local data storage.
            </List.Item>
            <List.Item>
              Your mobile device may provide you with choices around how and
              whether location or other data is shared with us.
            </List.Item>
            <List.Item>
              Heto does not control these choices, or default settings, which
              are offered by makers of your browser or mobile device.
            </List.Item>
          </List>
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Sharing and Disclosure</h5>
        </div>
        <div className="text-justify">
          There are times when communications and related content and other user
          information may be shared by Heto. This section discusses only how
          Heto may share user information. Organizations that use Heto may have
          their own policies for sharing and disclosure of information they can
          access through Heto. Heto may share information:
          <p className="mt10 mb10" />
          <List bulleted>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                With consent, to comply with legal process, or to protect Heto
                and our users.
              </span>{" "}
              When we have your consent or if we believe that disclosure is
              reasonably necessary to comply with a law, regulation or legal
              request; to protect the safety, rights, or property of the public,
              any person, or Heto; or to detect, prevent, or otherwise address
              fraud, security or technical issues. If we receive a law
              enforcement or other third party request for information we will
              provide prior notice to the subject of the request where we are
              legally permitted to do so.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                With third party service providers and agents.
              </span>{" "}
              We may employ third party companies or individuals to process
              personal information on our behalf based on our instructions and
              in compliance with this Privacy Policy. For example, we may share
              data with a security consultant to help us get better at
              preventing unauthorized access or with an email vendor to send
              messages on our behalf. We may also share data with hosting
              providers, payment processors, marketing vendors, and other
              consultants who work on our behalf.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>Integrations.</span> If you
              add an integration, Hetomay share information about you or your
              institution with the provider of the integration. Heto is not
              responsible for how the provider of the integration may collect
              and use your data.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                About you with your organization or Institution
                Administrator(s).
              </span>
              <List.List>
                <List.Item>
                  There may be times when you contact Heto to help resolve an
                  issue specific to a Institution you are a member of. In order
                  to help resolve the issue, we may need to share your concern
                  with your administrator. When possible, we will try to mask or
                  remove any identifying information before sharing these
                  communications.
                </List.Item>
                <List.Item>
                  If we engage in a merger, acquisition, bankruptcy,
                  dissolution, reorganization, sale of some or all of Heto's
                  assets financing, acquisition of all or a portion of our
                  business, or similar transaction or proceeding that involves
                  the transfer of the information described in this Privacy
                  Policy.
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                For Business and Research Purposes.
              </span>
              <List.List>
                <List.Item>
                  We may also share aggregated or de-identified information with
                  our partners or others for business or research purposes. For
                  example, we may tell a prospective Hetocustomer the average
                  number of messages sent within a Heto institution in a day or
                  may partner with research firm or academics to explore
                  interesting questions about workplace communications. Again,
                  this policy is not intended to prohibit the disclosure and use
                  of aggregated or de-identified data.
                </List.Item>
              </List.List>
            </List.Item>
          </List>
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Security</h5>
        </div>
        <div className="text-justify">
          Heto takes reasonable steps to protect information you provide to us
          as part of your use of the Heto service from loss, misuse, and
          unauthorized access or disclosure. These steps take into account the
          sensitivity of the information we collect, process and store and the
          current state of technology. When you enter sensitive information
          (such as sign-in credentials) we encrypt the transmission of that
          information using secure socket layer technology (SSL). We follow
          generally accepted standards to protect the personal data submitted to
          us, both during transmission and once we receive it. However, no
          electronic or email transmission or digital storage mechanism is ever
          fully secure or error free.
          <p className="mt10 mb10" />
          To learn more about current practices and policies regarding security
          and confidentiality, please see our Security Practices; we keep that
          document updated as these practices evolve over time.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Age Restriction</h5>
        </div>
        <div className="text-justify">
          Heto is not directed to children under 13. If you learn that a minor
          child has provided us with personal information without your consent,
          please 
          <a href="mailto:heto@cabaana.com" target="_top">
            contact us
          </a>
          .
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Changes to this Privacy Policy</h5>
        </div>
        <div className="text-justify">
          We may change this policy from time to time, and if we do we'll post
          any changes on this page. If you continue to use Heto after those
          changes are in effect, you agree to the revised policy. If the changes
          are material, we may provide more prominent notice or seek your
          consent to the new policy.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>EU-U.S. Privacy Shield and U.S.-Swiss Safe Harbor</h5>
        </div>
        <div className="text-justify">
          Heto has self-certified to the EU-U.S. Privacy Shield.
          <p className="mt10 mb10" />
          Heto complies with the U.S.-Swiss Safe Harbor framework as set forth
          by the U.S. Department of Commerce regarding the collection, use, and
          retention of personal data from Switzerland. We may process some
          personal data from individuals or companies in Switzerland via other
          compliance mechanisms, including data processing agreements based on
          the EU Standard Contractual Clauses. To learn more about the
          U.S.-Swiss Safe Harbor program, and to view our certification, refer
          to{" "}
          <a
            href="https://2016.export.gov/safeharbor/"
            target="_blank"
            rel="noopener noreferrer license author"
          >
            https://2016.export.gov/safeharbor/
          </a>
          .
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Contacting Cabaana Inc.</h5>
        </div>
        <div className="text-justify">
          Please also feel free to contact us if you have any questions about
          Heto's Privacy Policy or practices. You may contact us at 
          <a href="mailto:heto@cabaana.com" target="_top">
            heto@cabaana.com
          </a>{" "}
          or at our mailing address below:
          <p className="mt5" />
          Cabaana Inc.
          <br />
          30700 Telegraph Road, Suite 1515
          <br />
          Bingham Farms, MI 48025
        </div>
      </Container>
    );
  };
  renderSecurity = () => {
    return (
      <Container className="mt10 mb20">
        <div className="text-justify">
          We take security seriously here at Heto/Cabaana Inc. Every person and
          team using our service expects their data to be secure and
          confidential. We understand how important the responsibility of
          safeguarding this data is to our customers and work to maintain that
          trust.
          <p className="mt10 mb10" />
          If you believe you have found a security vulnerability in Heto,
          please 
          <a href="mailto:heto@cabaana.com" target="_top">
            get in touch with our security team
          </a>
          .
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Security Practices</h5>
          <h4>Effective: June 1, 2018</h4>
        </div>
        <div className="text-justify">
          We take the security of your data very seriously at Heto. As
          transparency is one of the principles on which our company is built,
          we aim to be as clear and open as we can about the way we handle
          security.
          <p className="mt10 mb10" />
          If you have additional questions regarding security, we are happy to
          answer them. Please write to 
          <a href="mailto:feedback@hetoonline.com" target="_top">
            feedback@hetoonline.com
          </a>
           and we will respond as quickly as we can.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Confidentiality</h5>
        </div>
        <div className="text-justify">
          We place strict controls over our employees' access to the data you
          and your users make available via the Heto services, as more
          specifically defined in your agreement with Heto covering the use of
          the Heto services ("Customer Data"), and are committed to ensuring
          that Customer Data is not seen by anyone who should not have access to
          it. The operation of the Heto services requires that some employees
          have access to the systems which store and process Customer Data. For
          example, in order to diagnose a problem you are having with the Heto
          services, we may need to access your Customer Data. These employees
          are prohibited from using these permissions to view Customer Data
          unless it is necessary to do so. We have technical controls and audit
          policies in place to ensure that any access to Customer Data is
          logged.
          <p className="mt10 mb10" />
          All of our employees and contract personnel are bound to our policies
          regarding Customer Data and we treat these issues as matters of the
          highest importance within our company.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Personnel Practices</h5>
        </div>
        <div className="text-justify">
          Heto conducts background checks on all employees before employment,
          and employees receive security training during onboarding as well as
          on an ongoing basis. All employees are required to read and sign our
          comprehensive information security policy covering the security,
          availability, and confidentiality of the Heto services.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Compliance</h5>
        </div>
        <div className="text-justify">
          The following security-related audits and certifications are
          applicable to the Heto services:
          <p className="mt10 mb10" />
          <List bulleted>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>
                Service Organization Control (SOC) Reports:
              </span>{" "}
              Heto has undergone a SOC 2 audit, and a copy of Heto's most recent
              report is available upon request from your Account Manager.
            </List.Item>
            <List.Item>
              <span style={{ fontWeight: "bold" }}>PCI:</span> Heto is not
              currently a PCI-certified Service Provider. We are a PCI Level 4
              Merchant and have completed the Payment Card Industry Data
              Security Standard's SAQ-A, allowing us to use a third party to
              process your credit card information securely.
            </List.Item>
          </List>
          The environment that hosts the Heto services maintains multiple
          certifications for its data centers, including ISO 27001 compliance,
          PCI Certification, and SOC reports. For more information about their
          certification and compliance, please visit the{" "}
          <a
            href="https://aws.amazon.com/security/"
            target="_blank"
            rel="noopener noreferrer license author"
          >
            AWS Security website
          </a>
           and the{" "}
          <a
            href="https://aws.amazon.com/compliance/"
            target="_blank"
            rel="noopener noreferrer license author"
          >
            AWS Compliance website
          </a>
          .
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Security Features for Team Members & Administrators</h5>
        </div>
        <div className="text-justify">
          In addition to the work we do at the infrastructure level, we provide
          Team Administrators of paid versions of the Heto services with
          additional tools to enable their own users to protect their Customer
          Data.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Access Logging</h5>
        </div>
        <div className="text-justify">
          Detailed access logs are available both to users and administrators of
          paid teams. We log every time an account signs in, noting the type of
          device used and the IP address of the connection.
          <p className="mt10 mb10" />
          Team Administrators and owners of Heto can review consolidated access
          logs for their whole team. We also make it easy for administrators to
          remotely terminate all connections and sign out all devices
          authenticated to the Heto services at any time, on-demand.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Deletion of Customer Data</h5>
        </div>
        <div className="text-justify">
          Heto provides the option for Team Owners/Administrators to delete
          Customer Data at any time during a subscription term. Within 24 hours
          of Team Owner initiated deletion, Heto hard deletes all information
          from currently-running production systems (excluding team and channel
          names, and search terms embedded in URLs in web server access logs).
          Heto services backups are destroyed after 14 days.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Data Encryption In Transit and At Rest</h5>
        </div>
        <div className="text-justify">
          The Heto services support the latest recommended secure cipher suites
          and protocols to encrypt all traffic in transit. Customer Data is
          encrypted at rest. We monitor the changing cryptographic landscape
          closely and work promptly to upgrade the service to respond to new
          cryptographic weaknesses as they are discovered and implement best
          practices as they evolve. For encryption in transit, we do this while
          also balancing the need for compatibility for older clients.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Availability</h5>
        </div>
        <div className="text-justify">
          We understand that you rely on the Heto services to work. We're
          committed to making Heto a highly-available service that you can count
          on. Our infrastructure runs on systems that are fault tolerant, for
          failures of individual servers or even entire data centers. Our
          operations team tests disaster-recovery measures regularly and staffs
          an around-the-clock on-call team to quickly resolve unexpected
          incidents.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Disaster Recovery</h5>
        </div>
        <div className="text-justify">
          Customer Data is stored redundantly at multiple locations in our
          hosting provider's data centers to ensure availability. We have
          well-tested backup and restoration procedures, which allow recovery
          from a major disaster. Customer Data and our source code are
          automatically backed up nightly. The Operations team is alerted in
          case of a failure with this system. Backups are fully tested at least
          every 120 days to confirm that our processes and tools work as
          expected.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Network Protection</h5>
        </div>
        <div className="text-justify">
          In addition to sophisticated system monitoring and logging, we have
          implemented two-factor authentication for all server access across our
          production environment. Firewalls are configured according to industry
          best practices and unnecessary ports are blocked by configuration with
          AWS Security Groups.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Logging</h5>
        </div>
        <div className="text-justify">
          Heto maintains an extensive, centralized logging environment in its
          production environment which contains information pertaining to
          security, monitoring, availability, access, and other metrics about
          the Heto services. These logs are analyzed for security events via
          automated monitoring software, overseen by the security team.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Incident Management & Response</h5>
        </div>
        <div className="text-justify">
          In the event of a security breach, Heto will promptly notify you of
          any unauthorized access to your Customer Data. Heto has incident
          management policies and procedures in place to handle such an event.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>External Security Audits</h5>
        </div>
        <div className="text-justify">
          We contract with respected external security firms who perform regular
          audits of the Heto services to verify that our security practices are
          sound and to monitor the Heto services for new vulnerabilities
          discovered by the security research community. In addition to periodic
          and targeted audits of the Heto services and features, we also employ
          the use of continuous hybrid automated scanning of our web platform.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Product Security Practices</h5>
        </div>
        <div className="text-justify">
          New features, functionality, and design changes go through a security
          review process facilitated by the security team. In addition, our code
          is audited with automated static analysis software, tested, and
          manually peer-reviewed prior to being deployed to production. The
          security team works closely with development teams to resolve any
          additional security concerns that may arise during development.
        </div>
        <div className="subtitle mt10 mb10">
          <h5>Report a Vulnerability</h5>
        </div>
        <div className="text-justify">
          If you believe you have found a security vulnerability on Heto, please
          let us know right away. We will investigate all reports and do our
          best to quickly fix valid issues.
          <p className="mt10 mb10" />
          For other security questions or issues, please email 
          <a href="mailto:heto@cabaana.com" target="_top">
            heto@cabaana.com
          </a>
          .
        </div>
      </Container>
    );
  };
  renderTabs = () => {
    const { selectedLegal } = this.state;
    return (
      <Grid.Column mobile={16} tablet={16} computer={14}>
        <div className="legal-tabs">
          <Tab
            activeIndex={selectedLegal}
            menu={{ secondary: true, pointing: true }}
            panes={this.state.panes}
            onTabChange={this.onSelectedLegal}
          />
        </div>
      </Grid.Column>
    );
  };
  renderContentLegal = () => {
    const { panes, selectedLegal } = this.state;
    switch (panes[selectedLegal].name) {
      case "Privacy":
        return this.renderPrivacy();
      case "Security":
        return this.renderSecurity();
      default:
        return this.renderTermsOfService();
    }
  };
  render() {
    return [
      <Breadcrumbs
        key="breadcrumbs-about"
        crumbs={[
          { pathName: "Home", path: "/" },
          { pathName: "Legal", path: null },
        ]}
      />,
      <div
        key="content-about"
        className="container body nopadding website-about"
      >
        <section className="about aboutus1 text-center">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <div className="pl25 pr25">
                <h1 className="title text-teal website">Legal</h1>
                <div className="row justify-content-center">
                  <div className="col-sm-1 col-xs-6 barseparator mt5" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="legal">
          <div className="row">
            <Grid stackable>
              <Grid.Column computer={1} only="computer" />
              {this.renderTabs()}
              <Grid.Column computer={1} only="computer" />
              <Grid.Column computer={1} only="computer" />
              <Grid.Column mobile={16} tablet={16} computer={14}>
                <Element
                  name="legal"
                  id="containerLegal"
                  className="content skejule-scroll"
                >
                  {this.renderContentLegal()}
                </Element>
              </Grid.Column>
              <Grid.Column computer={1} only="computer" />
            </Grid>
          </div>
        </section>
        <Newsletter />
        <PreFooter />
      </div>,
    ];
  }
}

export default LegalPresentational;
