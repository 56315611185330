// @flow
import { LocalStoreActions } from "../actions/actions";
import { LocalStore } from "../utils/storage/localStore";
// LocalStore reducer

// The initial application state
type State = {
  +numKeys: number,
  +keys: ?string,
};
type Action = {
  +type: string,
  +payload: Object,
};

let initialState = {
  numKeys: 0,
  keys: null,
};

export default function localStore(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case LocalStoreActions.LOCALSTORE_SAVE:
      const { key, value } = action.payload;
      const keys = LocalStore.Save(key, value);
      return Object.assign({}, state, {
        numKeys: state.numKeys + 1,
        keys: keys,
      });
    case LocalStoreActions.LOCALSTORE_CLEAR_ALL:
      LocalStore.ClearAll();
      return initialState;
    // same state (not change)
    default:
      return state;
  }
}
