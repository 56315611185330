// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
// Component Breadcrumbs.

const bindActions = (dispatch) => {
  return {
    goTo: (to) => dispatch(push(to)),
  };
};

const Breadcrumbs: React.ComponentType<any> = connect(
  null,
  bindActions
)((props): React$Element<any> => {
  const onGoTo = (path) => () => {
    if (path) {
      props.goTo(path);
    }
  };
  return (
    <div className="container header website-breadcrumbs">
      <section className="breadcrumbs">
        <div className="row">
          <div className="col-sm-12 hidden-xs visible-sm visible-md visible-lg">
            {props.crumbs.map(({ pathName, path }) => (
              <div key={`${pathName}-${path}`} className="crumb">
                <a
                  style={path ? { cursor: "pointer" } : {}}
                  onClick={onGoTo(path)}
                >
                  {pathName}
                </a>
              </div>
            ))}
          </div>
          <div className="col-xs-12 visible-xs hidden-sm hidden-md hidden-lg">
            <div className="crumb-mobile">
              <a
                style={{ cursor: "pointer" }}
                onClick={onGoTo(props.crumbs[props.crumbs.length - 2].path)}
              >
                {props.crumbs[props.crumbs.length - 2].pathName}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Breadcrumbs;
