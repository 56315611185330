// @flow
import * as React from "react";
import { Field } from "redux-form";
import { Button, Message } from "semantic-ui-react";
import Phone from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
// Assets
import logo from "../../images/logoheto.png";

type Props = {
  isRecaptchaValid: boolean,
  errorSuccess: "" | "Loading" | "Error" | "Success",
  invalid: boolean,
  renderCaptcha: () => void,
  onSubmit: (payload: any) => void,
  onDismiss: () => void,
};

const renderInputField: React.ComponentType<*> = ({
  input,
  id,
  type,
  className,
  placeholder,
  InputTextArea,
}) => (
  <InputTextArea
    {...input}
    id={id}
    type={type}
    className={className}
    placeholder={placeholder}
  />
);

const renderPhoneField: React.ComponentType<*> = ({ input }) => (
  <Phone
    className="row row-telephone heto-contact-phone mt20"
    country="US"
    flags={flags}
    onChange={(value) => input.onChange(value || "")}
    value={input.value}
    placeholder="Phone Number"
  />
);

class Adea2022TrialView extends React.Component<Props> {
  render() {
    const {
      isRecaptchaValid,
      renderCaptcha,
      errorSuccess,
      invalid,
      onSubmit,
      onDismiss,
    } = this.props;
    return (
      <div className="container body nopadding website-adea-2022-trial">
        <section className="adea-2022-trial header text-center pt50">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <h1 className="text-teal website">ADEA 2022</h1>
              <div className="row justify-content-center">
                <div className="col-sm-1 col-xs-5 barseparator mt5" />
              </div>
              <div className="logo img-responsive">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
        </section>
        <section className="adea-2022-trial form">
          <div className="row">
            <div className="col-sm-12">
              <div className="pl25 pr25">
                <h1 className="title text-teal website">Heto Trial Access </h1>
                <div className="col-xs-3 barseparator mt5" />
              </div>
              <div className="p50">
                <div className="form pt0">
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label htmlFor="name" className="hidden">
                        First Name
                      </label>
                      <Field
                        name="firstName"
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                        InputTextArea="input"
                        component={renderInputField}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastname" className="hidden">
                        Last Name
                      </label>
                      <Field
                        name="lastName"
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name"
                        InputTextArea="input"
                        component={renderInputField}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="schoolName" className="hidden">
                        School Name
                      </label>
                      <Field
                        name="schoolName"
                        type="text"
                        className="form-control"
                        id="schoolName"
                        placeholder="School Name"
                        InputTextArea="input"
                        component={renderInputField}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email" className="hidden">
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        InputTextArea="input"
                        component={renderInputField}
                      />
                    </div>
                    <div className="form-group">
                      <Field name="contactPhone" component={renderPhoneField} />
                    </div>
                    {process.env.REACT_APP_ENV === "prod" && (
                      <div className="form-group">{renderCaptcha()}</div>
                    )}
                    {(errorSuccess === "Success" ||
                      errorSuccess === "Error") && (
                      <Message
                        onDismiss={onDismiss}
                        success={errorSuccess === "Success"}
                        error={errorSuccess === "Error"}
                        className="p10"
                        header={errorSuccess}
                        content={
                          errorSuccess === "Success"
                            ? "Form sent successfully"
                            : "Heto trial access form have not been sent. Try again ?"
                        }
                      />
                    )}
                    <div className="text-center">
                      <Button
                        type="submit"
                        className="btn btn-default"
                        style={
                          !isRecaptchaValid || invalid
                            ? { cursor: "default", opacity: 0.5 }
                            : {}
                        }
                        loading={errorSuccess === "Loading"}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Adea2022TrialView;
