// @flow
import { Users } from "../actions/actions";
// Users Reducer
type StateSignUp = {
  +email: string,
  +firstName: string,
  +lastName: string,
  +stepsAllowed: {
    +invitation: boolean,
    +account: boolean,
    +password: boolean,
    +done: boolean,
  },
};
type State = {
  +signup: StateSignUp,
};
type Action = {
  +type: string,
  +payload: Object,
};

const initialStateSignUp: StateSignUp = {
  email: "",
  firstName: "",
  lastName: "",
  //It is needed to follow right steps else redirect to index
  stepsAllowed: {
    invitation: true,
    account: false,
    password: false,
    done: false,
  },
};
const initialState: State = {
  signup: initialStateSignUp,
};

export default function users(
  state: State = initialState,
  action: Action
): State {
  let signup: Object;
  switch (action.type) {
    // FOR sign up process:
    case Users.USERS_SIGN_UP_CHECK_INVITATION_SUCCESS:
      signup = Object.assign({}, state.signup, {
        email: action.payload.email,
        firstName: action.payload.firstName || "",
        lastName: action.payload.lastName || "",
        stepsAllowed: {
          invitation: true,
          account: true,
          password: false,
          done: false,
        },
      });
      return Object.assign({}, state, { signup: signup });
    case Users.USERS_SIGN_UP_SET_ACCOUNT:
      signup = Object.assign({}, state.signup, {
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        stepsAllowed: {
          invitation: true,
          account: true,
          password: true,
          done: false,
        },
      });
      return Object.assign({}, state, { signup: signup });
    case Users.USERS_SIGN_UP_SUCCESS:
      signup = Object.assign({}, state.signup, {
        stepsAllowed: {
          invitation: true,
          account: true,
          password: true,
          done: true,
        },
      });
      return Object.assign({}, state, { signup: signup });
    // initial state Sign Up
    case Users.USERS_SIGN_UP_DONE:
      return Object.assign({}, state, { signup: initialStateSignUp });
    // initial state
    // return initialState;
    // same state (not change)
    default:
      return state;
  }
}
