// @flow
import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { scroller } from "react-scroll";
import MenuPresentational from "./MenuPresentational";
import "./Menu.css";

type Props = {
  location: Object,
  goTo: Function,
};
type State = {
  showMenu: boolean,
  activeMenu: string,
  openMobileMenu: boolean,
};

class MenuContainer extends React.Component<Props, State> {
  state = {
    showMenu: true,
    activeMenu: "/",
    openMobileMenu: false,
  };
  onChangeMenu = (path) => () => {
    if (this.state.activeMenu !== path) {
      const { goTo } = this.props;
      goTo(path);
      this.setState({ openMobileMenu: false });
    } else {
      if (path === "/#what") {
        scroller.scrollTo("myScrollToWhat", {
          duration: 1500,
          delay: 100,
          smooth: true,
        });
      }
      if (path === "/#features") {
        scroller.scrollTo("myScrollToFeatures", {
          duration: 1500,
          delay: 100,
          smooth: true,
        });
      }
    }
  };
  onOpenMobileMenu = () => {
    this.setState({ openMobileMenu: !this.state.openMobileMenu });
  };
  componentWillMount() {
    this.setState({
      activeMenu: `${this.props.location.pathname}${this.props.location.hash}`,
      showMenu:
        this.props.location.pathname.indexOf("/create/") === -1 &&
        this.props.location.pathname.indexOf("/signup/") === -1 &&
        this.props.location.pathname.indexOf("/activation-account/") === -1 &&
        this.props.location.pathname.indexOf("/public/") === -1 &&
        this.props.location.pathname.indexOf("/download-file/") === -1 &&
        this.props.location.pathname.indexOf("/adea2022trial") === -1,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.activeMenu !==
      `${nextProps.location.pathname}${nextProps.location.hash}`
    ) {
      this.setState({
        activeMenu: `${nextProps.location.pathname}${nextProps.location.hash}`,
        showMenu:
          nextProps.location.pathname.indexOf("/create/") === -1 &&
          nextProps.location.pathname.indexOf("/signup/") === -1 &&
          nextProps.location.pathname.indexOf("/activation-account/") === -1 &&
          nextProps.location.pathname.indexOf("/public/") === -1 &&
          nextProps.location.pathname.indexOf("/adea2022trial") === -1,
      });
    }
  }

  render() {
    const { showMenu, activeMenu, openMobileMenu } = this.state;
    if (showMenu) {
      return (
        <MenuPresentational
          activeMenu={activeMenu}
          onChangeMenu={this.onChangeMenu}
          openMobileMenu={openMobileMenu}
          onOpenMobileMenu={this.onOpenMobileMenu}
        />
      );
    }
    return null;
  }
}

const bindActions = (dispatch) => {
  return {
    goTo: (to) => dispatch(push(to)),
  };
};

const Menu: React.ComponentType<any> = withRouter(
  connect(null, bindActions)(MenuContainer)
);

export default Menu;
