// @flow
import { createAction } from "redux-actions";

//Public Url
export const PUBLIC_URL: string = "PUBLIC_URL";
export const PUBLIC_URL_REQUEST: string = "PUBLIC_URL/REQUEST";
export const PUBLIC_URL_SUCCESS: string = "PUBLIC_URL/SUCCESS";
export const PUBLIC_URL_FAILURE: string = "PUBLIC_URL/FAILURE";
export const publicUrl = createAction(PUBLIC_URL);
export const publicUrlRequest = createAction(PUBLIC_URL_REQUEST);
export const publicUrlSuccess = createAction(PUBLIC_URL_SUCCESS);
export const publicUrlFailure = createAction(PUBLIC_URL_FAILURE);
