// @flow
import * as React from "react";
import { withRouter, Link } from "react-router-dom";
// Component Footer.
const Footer = withRouter((props): React$Element<any> | null => {
  if (
    props.location.pathname.indexOf("/create/") === -1 &&
    props.location.pathname.indexOf("/signup/") === -1 &&
    props.location.pathname.indexOf("/activation-account/") === -1 &&
    props.location.pathname.indexOf("/public/") === -1 &&
    props.location.pathname.indexOf("/download-file/") === -1
  ) {
    return (
      <div className="container-fluid nopadding website-footer">
        <footer className="text-center pt30 pb30">
          <a
            href="https://www.cabaana.com/"
            target="_blank"
            rel="noopener noreferrer license author"
          >
            &copy; Cabaana Inc. {new Date().getFullYear()}
          </a>
          <span className="ml10 mr10">|</span>
          <Link to="/legal">Legal</Link>
        </footer>
      </div>
    );
  }
  return null;
});

export default Footer;
