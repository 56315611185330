// @flow
import * as React from "react";
import "./CreateCompany.css";

type Props = {
  create: Object,
  token: string,
  match: Object,
};

type State = {
  component: ?React.ElementType,
};

class CreateCompanyContainer extends React.Component<Props, State> {
  state = {
    component: null,
  };
  loadStepProcess = (step: string) => {
    window.logger.log("loadStepProcess", this.props);
    (async () => {
      const component = await import(
        `./${step}/${step.charAt(0).toUpperCase() + step.slice(1)}Container`
      );
      this.setState({ component: component.default });
    })();
  };
  componentWillMount() {
    const { step } = this.props.match.params;
    this.loadStepProcess(step);
  }
  componentWillReceiveProps(nextProps: Object) {
    const { step } = nextProps.match.params;
    if (this.props.match.params.step !== step) {
      this.loadStepProcess(step);
    }
  }
  render() {
    const { create, token, ...remainProps } = this.props;
    const { component: Component } = this.state;
    return (
      Component && <Component company={create} token={token} {...remainProps} />
    );
  }
}

export default CreateCompanyContainer;
