// @flow
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { scroller } from "react-scroll";
import HomePresentational from "./HomePresentational";

type Props = {
  location: Object,
  goTo: Function,
};

class HomeContainer extends React.Component<Props> {
  goToContact = () => {
    const { goTo } = this.props;
    goTo("/contact");
  };
  scrollerTo = (element: string) => {
    scroller.scrollTo(element, {
      duration: 1500,
      delay: 100,
      smooth: true,
    });
  };
  componentWillMount() {
    if (document.body && document.body.className) {
      document.body.className = "website";
    }
  }
  componentDidMount() {
    if (this.props.location.hash === "#what") {
      this.scrollerTo("myScrollToWhat");
    }
    if (this.props.location.hash === "#features") {
      this.scrollerTo("myScrollToFeatures");
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.location !== nextProps.location &&
      this.props.location.hash !== nextProps.location.hash &&
      (nextProps.location.hash === "#what" ||
        nextProps.location.hash === "#features")
    ) {
      if (nextProps.location.hash === "#what") {
        this.scrollerTo("myScrollToWhat");
      }
      if (nextProps.location.hash === "#features") {
        this.scrollerTo("myScrollToFeatures");
      }
    }
  }
  render() {
    return (
      <HomePresentational
        goToContact={this.goToContact}
        goToFeatures={this.scrollerTo}
      />
    );
  }
}

const bindActions = (dispatch) => {
  return {
    goTo: (path) => dispatch(push(path)),
  };
};

export default connect(null, bindActions)(HomeContainer);
