// @flow
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
// Component Newsletter.

const bindActions = (dispatch) => {
  return {
    goToContact: () => dispatch(push("/contact")),
  };
};

const Newsletter: React.ComponentType<any> = connect(
  null,
  bindActions
)((props): React$Element<any> => {
  return (
    <section className="newsletter container">
      <div className="row">
        <div className="col-sm-8">
          <h4>Take control of your efficiency.</h4>
          <h4>
            Give Heto a chance & improve the way with which you administer your
            programs.
          </h4>
        </div>
        <div className="col-sm-4">
          <div className="buttons">
            <button
              className="text-teal right website"
              onClick={props.goToContact}
            >
              Contact us
            </button>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Newsletter;
