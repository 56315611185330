// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SignInContainer from "./SignInContainer";
import ProtectedRoute from "../shared/ProtectedRoute";

const SignInRoute: React.ComponentType<any> = withRouter(
  connect(
    ({ config, company: { companyDomains, companyDomainSelected }, auth }) => {
      return {
        component: SignInContainer,
        restProps: { config, companyDomains, companyDomainSelected },
        isAllowedRoute: true,
        redirectTo: "/",
      };
    }
  )(ProtectedRoute)
);

export default SignInRoute;
