// @flow
// API Reducer
import { API } from "../api/API";
import { Api } from "../actions/actions";

type State = Object;
type Action = {
  +type: string,
  +payload: Object,
};

// The initial application state
const initialState = {
  initAPI: false,
};

export default function api(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case Api.INIT_API:
    case Api.SET_TOKEN:
    case Api.UNSET_TOKEN:
      return new API(action.payload.url, action.payload.token);
    // initial state
    default:
      return state;
  }
}
