// @flow
import * as React from "react";
import { Grid, Image, Message } from "semantic-ui-react";
import somethingWentWrong from "../../images/something-went-wrong.svg";
// Component generic for some error info.
const ErrorComponent = ({
  className,
  errorMessageHeader,
  errorMessageContent,
}: Object): React$Element<any> => (
  <Grid.Row className={`error-component ${className || ""}`}>
    <Grid.Column width={16} className="mb10">
      <Image
        src={somethingWentWrong}
        className="illustration mb50 mt50"
        alt="illustration-empty"
        centered
      />
      <h1 className="text-message text-center">Oops!</h1>
      <div className="text-message description text-center">
        Looks like something went wrong
      </div>
      {(errorMessageHeader || errorMessageContent) && (
        <div className="message-board mt20">
          <Message negative>
            {errorMessageHeader && (
              <span className="header">{errorMessageHeader}</span>
            )}
            {errorMessageContent && (
              <span className="content">
                <br />
                {errorMessageContent}
              </span>
            )}
          </Message>
        </div>
      )}
    </Grid.Column>
  </Grid.Row>
);

export default ErrorComponent;
