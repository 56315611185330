// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LegalContainer from "./LegalContainer";
import ProtectedRoute from "../shared/ProtectedRoute";

const LegalRoute: React.ComponentType<any> = withRouter(
  connect(() => {
    return {
      component: LegalContainer,
      restProps: null,
      isAllowedRoute: true,
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default LegalRoute;
