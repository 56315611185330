// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ResetPWDContainer from "./ResetPWDContainer";
import ProtectedRoute from "../shared/ProtectedRoute";

const ResetPWDRoute: React.ComponentType<any> = withRouter(
  connect(({ auth }, ownProps) => {
    const { subdomain } = ownProps.computedMatch.params;
    let isAuthenticated = false;
    if (auth && auth[subdomain]) {
      isAuthenticated = auth[subdomain].isAuthenticated;
    }
    return {
      component: ResetPWDContainer,
      restProps: { companyDomain: subdomain },
      isAllowedRoute: subdomain && !isAuthenticated,
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default ResetPWDRoute;
