// @flow
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { reduxForm, SubmissionError, formValueSelector } from "redux-form";
import debounce from "lodash/debounce";
import sortBy from "lodash/sortBy";
import * as Cookies from "js-cookie";
import { parseQueryParams } from "../../utils/";
import { Company, Auth } from "../../actions/actions";
import SignInPresentational from "./SignInPresentational";
import "./Signin.css";

type Props = {
  getAccounts: (payload: Object) => void,
  location: Object,
  config: {
    protocol: string,
    websitename: string,
    baseDomain: string,
    port: string,
    suffix: string,
  },
  companyDomains: Array<string>,
  companyDomainSelected: string,
  emailSelected: string,
  passwordSelected: string,
  error: string,
  submitting: boolean,
  valid: boolean,
  handleSubmit: Function,
  onSelectedCompanyDomain: (tenant: string) => void,
  onRemoveCompanyDomain: (tenant: string) => void,
  onSignIn: Function,
  goTo: Function,
};
type State = {
  errorEmail: boolean,
  errorPassword: boolean,
  errorApi: boolean,
  companyDomainNotFound: boolean,
  companyDomainsToAdd: Array<string>,
  isFetchingAccounts: boolean,
  accountsAvailable: Array<Object>,
  accountSelected: number,
  showModalToSubscription: boolean,
  showModalByMaintenance: boolean,
};

class SignInContainer extends React.Component<Props, State> {
  state = {
    errorEmail: false,
    errorPassword: false,
    errorApi: false,
    companyDomainNotFound: false,
    companyDomainsToAdd: [],
    isFetchingAccounts: true,
    accountsAvailable: [],
    accountSelected: -1,
    showModalToSubscription: false,
    showModalByMaintenance: false,
  };
  onCloseModalToSubscription = () =>
    this.setState({ showModalToSubscription: false });
  onCompanyDomainToAdd = (event, { value }) => {
    // eslint-disable-next-line
    const tenant = value.replace(/[^\w\-]/gi, "").toLowerCase();
    window.logger.log("onCompanyDomainToAdd", tenant);
    this.setState({
      companyDomainsToAdd: [...this.state.companyDomainsToAdd, tenant],
    });
  };
  onRemoveCompanyDomain = (companyToDelete: string) => (event: Object) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const {
      companyDomains,
      onRemoveCompanyDomain,
      companyDomainSelected,
    } = this.props;
    if (companyDomains.indexOf(companyToDelete) !== -1) {
      onRemoveCompanyDomain(companyToDelete);
    }
    if (companyDomainSelected === companyToDelete) {
      this.onSelectedCompanyDomain("");
    }
    this.setState({
      companyDomainsToAdd: this.state.companyDomainsToAdd.filter(
        (companyToFind) => companyToFind !== companyToDelete
      ),
    });
  };
  onSetCompanyDomain = (event, { field, value }) => {
    window.logger.log("onSetCompanyDomain", value);
    // eslint-disable-next-line
    this.onSelectedCompanyDomain(value.replace(/[^\w\-]/gi, "").toLowerCase());
  };
  onSelectedCompanyDomain = (tenant) => {
    window.logger.log("onSelectedCompanyDomain", tenant);
    const {
      onSelectedCompanyDomain,
      companyDomainSelected,
      emailSelected,
    } = this.props;
    if (companyDomainSelected !== tenant) {
      onSelectedCompanyDomain(tenant);
      if (tenant && emailSelected) {
        this.onCheckGetAccount();
      }
    }
  };
  onSetErrorFields = (key, error) => () => {
    const { errorApi } = this.state;
    let newState: State = {
      ...this.state,
      companyDomainNotFound: false,
    };
    if (errorApi) {
      newState = {
        ...this.state,
        errorEmail: false,
        errorPassword: false,
        errorApi: false,
        companyDomainNotFound: false,
      };
    }
    newState[key] = error;
    this.setState(newState);
  };
  tenantNotFound = () => {
    this.setState({ companyDomainNotFound: true });
    setTimeout(() => {
      this.setState({ companyDomainNotFound: false });
    }, 5000);
  };
  goToRecoveryPWD = () => {
    const { goTo } = this.props;
    goTo("/recovery-password");
  };
  goToContact = () => {
    const { goTo } = this.props;
    goTo("/contact");
  };
  goToSubscriptions = () => {
    const { config, companyDomainSelected, emailSelected } = this.props;
    const { accountsAvailable, accountSelected } = this.state;
    const { protocol, baseDomain, port, suffix } = config;
    Cookies.set(
      "authCookie",
      {
        user: accountsAvailable[accountSelected].user,
        email: emailSelected,
        status: "notActive",
        customerId: null,
        token: "",
        removeDomain: `${baseDomain}${suffix}`,
      },
      {
        domain: `${baseDomain}${suffix}`,
        expires: new Date(new Date().getTime() + 11 * 60 * 1000 + 1000),
      }
    );
    const pathToApp: string = `${protocol}${companyDomainSelected}.${baseDomain}${suffix}${
      port ? ":3002" : ""
    }/payments`;
    window.location = pathToApp;
  };
  onSubmit = (credentials) => {
    const { config, companyDomainSelected, onSignIn } = this.props;
    const { accountsAvailable, accountSelected } = this.state;
    return new Promise((resolve, reject) => {
      const formValues = {
        credentials,
        user:
          accountsAvailable.length > 0 && accountSelected >= 0
            ? accountsAvailable[accountSelected].user
            : undefined,
      };
      const callbacks = {
        callbackError: (msg = "") => {
          window.logger.log("errors - onSubmit", msg);
          const msgForgetPassword =
            "<br/>If your forgot the password, you may recover the same by clicking on the 'Forgot password?' link below";
          if (
            msg.indexOf("Operation forbidden on the tenant, must exist") > -1
          ) {
            this.tenantNotFound();
            resolve();
            return;
          } else if (msg.indexOf("User not found") > -1) {
            reject(
              new SubmissionError({
                _error: `Login failed for ${companyDomainSelected}. User not found, check your email. Please try again.`,
              })
            );
          } else if (
            msg.indexOf(
              "You entered an email address/password combination that does not match"
            ) > -1
          ) {
            reject(
              new SubmissionError({
                _error: `Login failed for ${companyDomainSelected}. You entered an email address/password combination that does not match, check your password. Please try again.${msgForgetPassword}`,
              })
            );
          } else if (
            msg.indexOf(
              "User account is not active, please contact with your administrator."
            ) > -1
          ) {
            reject(
              new SubmissionError({
                _error: `Login failed for ${companyDomainSelected}. User account is not active. Please contact with your school administrator, if you need this user account to be in active.`,
              })
            );
          } else if (
            msg.indexOf(
              "Your account is not active, please subrscribe to one of our subscription plans"
            ) > -1
          ) {
            this.setState({ showModalToSubscription: true });
            reject(new SubmissionError({ _error: "" }));
          } else {
            const utcTime = new Date(new Date().toUTCString());
            if (utcTime.getUTCDay() === 0) {
              const utcTimeHours = utcTime.getUTCHours();
              if (utcTimeHours > 1 && utcTimeHours < 8) {
                this.setState({ showModalByMaintenance: true });
                setTimeout(
                  () => this.setState({ showModalByMaintenance: false }),
                  5000
                );
                reject(
                  new SubmissionError({
                    _error: `Login failed for ${companyDomainSelected}. The system is currently under maintenance. Please try logging in after 4 hours. Sorry for the inconvenience. Thank you, Heto Support Team.`,
                  })
                );
              }
            }
            reject(
              new SubmissionError({
                _error: `Login failed for ${companyDomainSelected}. Please try again. If the problem persist, check with your school administrator.${msgForgetPassword}`,
              })
            );
          }
          this.setState({
            errorEmail: true,
            errorPassword: true,
            errorApi: true,
          });
        },
        callbackSuccess: (redirectToPayments: boolean) => {
          window.logger.log("Success - Sign In", redirectToPayments);
          const { protocol, baseDomain, port, suffix } = config;
          const pathToApp: string = `${protocol}${companyDomainSelected}.${baseDomain}${suffix}${
            port ? (redirectToPayments ? ":3002" : ":3001") : ""
          }${redirectToPayments ? "/payments" : ""}`;
          window.location = pathToApp;
          resolve();
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      onSignIn(payload);
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location.state && this.props.location.state.companyDomain) {
      this.onSelectedCompanyDomain(this.props.location.state.companyDomain);
    }
    const { length, subdomain } = parseQueryParams(this.props.location.search);
    if (length && subdomain === "notfound") {
      this.setState({ companyDomainNotFound: true });
      setTimeout(() => {
        this.setState({ companyDomainNotFound: false });
      }, 5000);
    }
    if (document.body && document.body.className) {
      document.body.className = "website";
    }
  }
  componentDidUpdate(prevProps: Props) {
    if (
      (this.props.emailSelected !== prevProps.emailSelected &&
        this.props.companyDomainSelected) ||
      (this.props.companyDomainSelected !== prevProps.companyDomainSelected &&
        this.props.emailSelected)
    ) {
      this.onCheckGetAccount();
    }
  }
  onCheckGetAccount = () => {
    const { companyDomainSelected, emailSelected } = this.props;
    if (
      companyDomainSelected &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+?\.?[A-Z]{2,4}$/i.test(emailSelected)
    ) {
      this._onGetAccounts({
        email: emailSelected,
        tenant: companyDomainSelected,
      });
    }
  };
  _onGetAccounts = debounce((body: { email: string, tenant: string }) => {
    this.setState({ isFetchingAccounts: true });
    this._onGettingAccounts(body).catch((error) => {
      window.logger.log("onGetAccounts", error);
    });
  }, 500);
  _onGettingAccounts = (body: { email: string, tenant: string }) => {
    const { getAccounts } = this.props;
    return new Promise((resolve, reject) => {
      const formValues = {
        body,
      };
      const callbacks = {
        callbackError: (error) => {
          window.logger.log("error - onGettingAccounts", error);
          this.setState({ isFetchingAccounts: false });
          reject();
        },
        callbackSuccess: (accountsAvailable) => {
          window.logger.log("success - onGettingAccounts", accountsAvailable);
          const accountsAvailableSorted = sortBy(accountsAvailable, ["status"]);
          this.setState({
            isFetchingAccounts: false,
            accountsAvailable: accountsAvailableSorted,
            accountSelected: accountsAvailableSorted.length > 0 ? 0 : -1,
          });
          resolve();
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      getAccounts(payload);
    });
  };
  onSetAccount = (
    event: Object,
    { value: accountSelected }: { value: number }
  ) =>
    this.setState({
      accountSelected,
    });
  render() {
    const {
      companyDomains,
      companyDomainSelected,
      emailSelected,
      error,
      handleSubmit,
      passwordSelected,
      submitting,
      valid,
    } = this.props;
    const {
      errorEmail,
      errorPassword,
      companyDomainsToAdd,
      isFetchingAccounts,
      accountsAvailable,
      accountSelected,
      showModalToSubscription,
      showModalByMaintenance,
    } = this.state;
    return (
      <SignInPresentational
        {...this.props.config}
        companyDomains={[...companyDomains, ...companyDomainsToAdd]}
        companyDomain={companyDomainSelected}
        onSetCompanyDomain={this.onSetCompanyDomain}
        onCompanyDomainToAdd={this.onCompanyDomainToAdd}
        onRemoveCompanyDomain={this.onRemoveCompanyDomain}
        companyDomainNotFound={this.state.companyDomainNotFound}
        onSubmit={handleSubmit(this.onSubmit)}
        submitting={submitting}
        setErrorFields={this.onSetErrorFields}
        error={error}
        errorEmail={errorEmail}
        errorPassword={errorPassword}
        errorCompanyDomain={
          emailSelected && passwordSelected && !companyDomainSelected
            ? "Your school is required"
            : ""
        }
        valid={(companyDomainSelected || false) && valid}
        goToRecoveryPWD={this.goToRecoveryPWD}
        goToContact={this.goToContact}
        isFetchingAccounts={isFetchingAccounts}
        accountsAvailable={accountsAvailable}
        accountSelected={accountSelected}
        onSetAccount={this.onSetAccount}
        onCloseModalToSubscription={this.onCloseModalToSubscription}
        showModalToSubscription={showModalToSubscription}
        showModalByMaintenance={showModalByMaintenance}
        goToSubscriptions={this.goToSubscriptions}
      />
    );
  }
}

// decorate the form component
const SignInForm = reduxForm({
  form: "signin",
  validate: (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+?\.?[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
      errors._error = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Write your Password";
    } else if (values.password.length < 8) {
      errors.password = "Password must be 8 characters or more";
      if (!errors._error) {
        errors._error = "Password must be 8 characters or more";
      }
    }
    return errors;
  },
})(SignInContainer);
const selector = formValueSelector("signin");

const bindActions = (dispatch) => {
  return {
    goTo: (path) => dispatch(push(path)),
    getAccounts: (payload) => dispatch(Auth.authFetchAccounts(payload)),
    onRemoveCompanyDomain: (companyDomain) =>
      dispatch(Company.companyRemoveCompanyDomain({ companyDomain })),
    onSelectedCompanyDomain: (companyDomain) =>
      dispatch(Company.companySelectedCompanyDomain({ companyDomain })),
    onSignIn: (payload) => dispatch(Auth.authSignIn(payload)),
  };
};

export default connect(
  (state) => ({
    emailSelected: selector(state, "email"),
    passwordSelected: selector(state, "password"),
  }),
  bindActions
)(SignInForm);
