// @flow
import * as React from "react";
import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";
import { Contact } from "../../actions/actions";
import Adea2022TrialView from "./Adea2022Trial.view";
// Styles
import "react-phone-number-input/style.css";
import "./Adea2022Trial.css";

type Props = Object;

class Adea2022TrialContainer extends React.Component<
  Props,
  {
    isRecaptchaValid: boolean,
    errorSuccess: "" | "Loading" | "Error" | "Success",
  }
> {
  recaptcha = null;
  state = {
    isRecaptchaValid: process.env.REACT_APP_ENV !== "prod",
    errorSuccess: "",
  };
  componentWillMount() {
    window.scrollTo(0, 0);
    if (document.body && document.body.className) {
      document.body.className = "website";
    }
  }
  resetForm = () => {
    const { reset } = this.props;
    setTimeout(() => {
      if (this.recaptcha) {
        this.recaptcha.reset();
      }
      this.onDismiss();
      reset();
    }, 5000);
  };
  onDismiss = () =>
    this.setState({ isRecaptchaValid: false, errorSuccess: "" });
  onSetSuccessRecaptcha = () => this.setState({ isRecaptchaValid: true });
  onSubmit = (values) => {
    if (this.state.isRecaptchaValid) {
      const { setContactForm } = this.props;
      this.setState({
        errorSuccess: "Loading",
      });
      return new Promise((resolve, reject) => {
        const formValues = {
          body: { ...values, type: "trial_signup" },
        };
        const callbacks = {
          callbackError: (msg) => {
            window.logger.log("Error Contact Form", msg);
            this.setState({
              errorSuccess: "Error",
            });
            this.resetForm();
            reject(new SubmissionError({ _error: msg }));
          },
          callbackSuccess: () => {
            window.logger.log("Success Contact Form");
            this.setState({
              errorSuccess: "Success",
            });
            this.resetForm();
            resolve();
          },
        };
        const payload = Object.assign({}, formValues, callbacks);
        setContactForm(payload);
      });
    }
  };
  renderCaptcha = () => {
    return (
      <ReCAPTCHA
        ref={(el) => {
          this.recaptcha = el;
        }}
        sitekey="6LcXFFQUAAAAAEupDNAksFHCVLGn-qXrZb8KQNm0"
        onChange={this.onSetSuccessRecaptcha}
      />
    );
  };
  render() {
    return (
      <Adea2022TrialView
        errorSuccess={this.state.errorSuccess}
        isRecaptchaValid={this.state.isRecaptchaValid}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        onDismiss={this.onDismiss}
        renderCaptcha={this.renderCaptcha}
        {...this.props}
      />
    );
  }
}
// decorate the form component
const ContactForm = reduxForm({
  form: "heto-demo-adea-trial-form",
  validate: (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "error firstName";
    }
    if (!values.lastName) {
      errors.lastName = "error lastName";
    }
    if (!values.schoolName) {
      errors.schoolName = "error schoolName";
    }
    if (!values.email) {
      errors.email = "error email 1";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+?\.?[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "error email 2";
    }
    if (values.contactPhone && !isValidPhoneNumber(values.contactPhone)) {
      errors.contactPhone = "error contactPhone";
    }
    return errors;
  },
})(Adea2022TrialContainer);

const bindActions = (dispatch) => {
  return {
    setContactForm: (payload) => dispatch(Contact.contactForm(payload)),
  };
};

export default connect(null, bindActions)(ContactForm);
