// @flow
import { put, select, call, all, fork, takeLatest } from "redux-saga/effects";
import CompanyApi from "../api/Company";
import { Api, Company } from "../actions/actions";

//Sagas for company

function* doCompanyCreateCheckInvitation(action) {
  yield put(Company.companyCreateCheckInvitationRequest());
  window.logger.log("doCompanyCreateCheckInvitation", action);
  const { token, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to check token for this invitation
    const { data } = yield call(CompanyApi.CheckToken, api, token);
    const { email, token: jwToken } = data;
    yield put(Api.setToken({ url: api.getBaseUrl(), token: jwToken }));
    yield put(Company.companyCreateCheckInvitationSuccess({ email: email }));
    callbackSuccess();
  } catch (error) {
    window.logger.log("ErrorSaga", error);
    yield put(Company.companyCreateCheckInvitationFailure());
    callbackError(error);
  }
}

function* doCompanyCreateCheckSubdomain(action) {
  yield put(Company.companyCreateCheckSubdomainRequest());
  window.logger.log("doCompanyCreateCheckSubdomain", action);
  const { subdomain, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to check subdomain (tenant available)
    const { data } = yield call(CompanyApi.CheckSubdomain, api, subdomain);
    const { isAvailable } = data;
    yield put(Company.companyCreateCheckSubdomainSuccess());
    callbackSuccess(isAvailable);
  } catch (error) {
    window.logger.log("ErrorSaga", error);
    yield put(Company.companyCreateCheckSubdomainFailure());
    callbackError(false);
  }
}

function* doCompanyCreateTenant(action) {
  yield put(Company.companyCreateTenantRequest());
  window.logger.log("doCompanyCreateTenant", action);
  const {
    subdomain,
    companyName,
    callbackSuccess,
    callbackError,
  } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to check subdomain (tenant available)
    const { data } = yield call(CompanyApi.CreateTenant, api, subdomain);
    const { subdomain: tenantDomain } = data;
    yield put(
      Company.companyCreateTenantSuccess({
        subdomain: tenantDomain,
        companyName: companyName,
      })
    );
    callbackSuccess();
  } catch (error) {
    window.logger.log("ErrorSaga", error);
    yield put(Company.companyCreateTenantFailure());
    callbackError(error);
  }
}

function* doCompanyCreate(action) {
  yield put(Company.companyCreateRequest());
  window.logger.log("doCompanyCreate", action);
  const { body, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to check subdomain (tenant available)
    yield call(CompanyApi.CreateCompany, api, body);
    yield put(Company.companyCreateSuccess());
    callbackSuccess();
  } catch (error) {
    window.logger.log("ErrorSaga", error);
    yield put(Company.companyCreateFailure());
    callbackError(error);
  }
}

export default function* companySagas(): Generator<*, *, *> {
  yield all([
    fork(
      takeLatest,
      Company.COMPANY_CREATE_CHECK_INVITATION,
      doCompanyCreateCheckInvitation
    ),
    fork(
      takeLatest,
      Company.COMPANY_CREATE_CHECK_SUBDOMAIN,
      doCompanyCreateCheckSubdomain
    ),
    fork(takeLatest, Company.COMPANY_CREATE_TENANT, doCompanyCreateTenant),
    fork(takeLatest, Company.COMPANY_CREATE, doCompanyCreate),
  ]);
}
