// @flow
import { Auth } from "../actions/actions";
// Auth Reducer
type authState = {
  +isAuthenticated: boolean,
  +token: string,
  +userId: ?string,
};
type Action = {
  +type: string,
  +payload: Object,
};

type State = ?authState;

const initialState: State = null;

export default function auth(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case Auth.AUTH_SIGNIN_SUCCESS:
      let authState = {};
      authState[action.payload.subdomain] = {
        isAuthenticated: true,
        token: action.payload.token,
        userId: action.payload.userId,
      };
      return Object.assign({}, state, authState);
    case Auth.AUTH_SIGNIN_FAILURE:
    case Auth.AUTH_SIGNUP_SUCCESS:
    case Auth.AUTH_SIGNUP_FAILURE:
      return state;
    default:
      return state;
  }
}
