// @flow
import baseConfig from "./base";

const prodConfig = (): Object => {
  let config = {
    appEnv: "production",
    baseImg: "https://{tenant}.hetoonline.com",
    api: {
      protocol: "",
      tenant: "",
      base: "/api",
      version: "v1",
    },
  };
  return Object.freeze(Object.assign({}, baseConfig, config));
};

export default prodConfig();
