// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreateCompanyContainer from "./CreateCompanyContainer";
import ProtectedRoute from "../shared/ProtectedRoute";

const CreateCompanyRoute: React.ComponentType<any> = withRouter(
  connect((state, ownProps) => {
    const { step, token } = ownProps.computedMatch.params;
    const { create } = state.company;
    window.logger.log("CreateCompanyPrivate", !!create.stepsAllowed[step]);
    return {
      component: CreateCompanyContainer,
      restProps: { config: state.config, create, token },
      isAllowedRoute: !!create.stepsAllowed[step],
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default CreateCompanyRoute;
