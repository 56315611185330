// @flow
import * as React from "react";
import { Grid, Icon, Image, Message, Modal } from "semantic-ui-react";
import ErrorComponent from "../shared/ErrorComponent";
import waitingForProcess from "../../images/waiting-for-process.svg";
import welcomeToHeto from "../../images/welcome-to-heto.svg";

type Props = {
  companyDomain: string,
  checkingToken: boolean,
  isValidToken: boolean,
  errorMessage: string,
};

class ActivateAccountPresentational extends React.Component<Props> {
  renderSuccessActivationAccount = () => {
    const { companyDomain } = this.props;
    return (
      <Grid.Row className="activation-account-success">
        <Grid.Column width={16} className="mb10">
          <Image
            src={welcomeToHeto}
            className="illustration mb50 mt50"
            alt="illustration-empty"
            centered
          />
          <h1 className="text-message text-center">Welcome to Heto!</h1>
          <div className="text-message description text-center">
            Your Heto account has been successfully activated
            <br />
            You may now Sign-in into {companyDomain} with your account
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  };
  render() {
    const { checkingToken, isValidToken, errorMessage } = this.props;
    return (
      <div id="main" className="activate-account">
        <Modal
          className="modal-create-company modal-activation-account seriously-fullscreen-mobile"
          dimmer={false}
          open={true}
          size={"small"}
        >
          <Modal.Header>
            <Modal.Header>
              {checkingToken && <h4>Checking your activation account</h4>}
            </Modal.Header>
          </Modal.Header>
          {checkingToken ? (
            <Modal.Content>
              <Image
                src={waitingForProcess}
                className="illustration mb50 mt50"
                alt="illustration-empty"
                centered
              />
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  We are checking your validation account.
                </Message.Content>
              </Message>
            </Modal.Content>
          ) : (
            <Modal.Content>
              {isValidToken ? (
                this.renderSuccessActivationAccount()
              ) : (
                <ErrorComponent
                  className="activation-account-error"
                  errorMessageHeader="Token is not valid, check your email please."
                  errorMessageContent={errorMessage}
                />
              )}
            </Modal.Content>
          )}
        </Modal>
      </div>
    );
  }
}

export default ActivateAccountPresentational;
