// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
import * as React from "react";
import { Field } from "redux-form";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Icon,
  Image,
  List,
  Message,
  Segment,
} from "semantic-ui-react";
import Breadcrumbs from "../shared/Breadcrumbs";
import Newsletter from "../shared/Newsletter";
import PreFooter from "../shared/PreFooter";
import iconX from "../../images/x.svg";

const renderInputField: React.ComponentType<*> = ({
  input,
  placeholder,
  type,
  className,
  onReset,
  meta: { active },
}) => (
  <Form.Field>
    <Form.Input
      {...input}
      icon={
        input.value.length > 0 && (
          <Image src={iconX} alt="x" onClick={onReset} />
        )
      }
      className={!active ? className : ""}
      fluid
      placeholder={placeholder}
      type={type}
    />
  </Form.Field>
);

type Props = {
  companyDomain: string,
  errorSuccess: string,
  strongerPassword: string,
  error: string,
  submitting: boolean,
  valid: boolean,
  dirty: boolean,
  onSubmit: Function,
  onStrongerPassword: Function,
  reset: Function,
  goToSignIn: Function,
  hasLength: boolean,
  hasLowerCase: boolean,
  hasUpperCase: boolean,
  hasNumber: boolean,
  hasSymbols: boolean,
};

class RecoveryPWDPresentational extends React.Component<
  Props,
  { showPassword: boolean }
> {
  state = {
    showPassword: false,
  };
  render() {
    const {
      companyDomain,
      onSubmit,
      errorSuccess,
      onStrongerPassword,
      strongerPassword,
      error,
      submitting,
      valid,
      dirty,
      reset,
      goToSignIn,
      hasLength,
      hasLowerCase,
      hasUpperCase,
      hasNumber,
      hasSymbols,
    } = this.props;
    const { showPassword } = this.state;
    const isStrongPassword = strongerPassword === "great";
    return [
      <Breadcrumbs
        key="breadcrumbs-reset-pwd"
        crumbs={[
          { pathName: "Home", path: "/" },
          { pathName: "Sign In", path: "/signin" },
          { pathName: "Reset Password", path: null },
        ]}
      />,
      <div
        key="content-reset-pwd"
        className="container-fluid body website-reset-pwd"
      >
        <section className="input-form mb100">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
              <h1 className="title text-teal text-center website">
                Reset Password for {companyDomain}
              </h1>
              <div className="form mt30 modal-create-company reset-password seriously-fullscreen-mobile">
                <Form
                  loading={submitting}
                  onSubmit={onSubmit}
                  success={errorSuccess === "success" && !error && !submitting}
                  error={
                    errorSuccess === "error" &&
                    !submitting &&
                    error &&
                    error !== "passwordNotMatch"
                  }
                >
                  <Field
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    component={renderInputField}
                    onReset={reset}
                    onChange={onStrongerPassword}
                    className={`${
                      dirty && !valid && error === "passwordNotMatch"
                        ? "negative"
                        : !valid || submitting
                        ? "warning"
                        : "success"
                    }`}
                  />
                  <Field
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    component={renderInputField}
                    onReset={reset}
                    className={`mb0 ${
                      dirty && !valid && error === "passwordNotMatch"
                        ? "negative"
                        : !valid || submitting
                        ? ""
                        : "success"
                    }`}
                  />
                  <div className="text-right mr10">
                    <Checkbox
                      checked={showPassword}
                      className="fluid mt10 z-index"
                      label={
                        <label style={{ fontSize: "1.25rem" }}>
                          {showPassword ? "Hide" : "Show"} Passwords
                          <Icon
                            className="skejule-teal ml5"
                            name={showPassword ? "eye slash" : "eye"}
                          />
                        </label>
                      }
                      name="all-users-selected"
                      onClick={() =>
                        this.setState({ showPassword: !showPassword })
                      }
                    />
                  </div>
                  <Container
                    className={`strengthmeter ${
                      dirty ? strongerPassword : "vweak"
                    }`}
                  >
                    <Segment.Group horizontal className="meter br0 mb0">
                      <Segment className="first nopadding" />
                      <Segment className="second nopadding" />
                      <Segment className="third nopadding" />
                      <Segment className="fourth nopadding" />
                    </Segment.Group>
                    <label className="vweak fullwidth text-right pr5">
                      very weak
                    </label>
                    <label className="weak fullwidth text-right pr5">
                      weak
                    </label>
                    <label className="soso fullwidth text-right pr5">
                      so-so
                    </label>
                    <label className="good fullwidth text-right pr5">
                      good
                    </label>
                    <label className="great fullwidth text-right pr5">
                      great!
                    </label>
                  </Container>
                  <Container
                    className={`text-left hint mb10 ${
                      isStrongPassword ? "success" : "warning"
                    }`}
                  >
                    Your password must be:
                    <List bulleted className="ml30">
                      <List.Item className={hasLength ? "success" : ""}>
                        A minimum of eight characters.
                      </List.Item>
                      <List.Item className={hasUpperCase ? "success" : ""}>
                        At least one uppercase letter.
                      </List.Item>
                      <List.Item className={hasLowerCase ? "success" : ""}>
                        At least one lowercase letter.
                      </List.Item>
                      <List.Item className={hasNumber ? "success" : ""}>
                        At least one number.
                      </List.Item>
                      <List.Item className={hasSymbols ? "success" : ""}>
                        At least one special character.
                      </List.Item>
                    </List>
                  </Container>
                  <Button
                    loading={submitting}
                    disabled={!valid || !isStrongPassword || submitting}
                    fluid
                    type="submit"
                    className={`${valid ? "skejule" : ""} text-center mt25`}
                  >
                    Reset
                  </Button>
                  <div className="message mb20">
                    <span className="forgotpwd mr10">
                      <a style={{ cursor: "pointer" }} onClick={goToSignIn}>
                        Cancel
                      </a>
                    </span>
                  </div>
                  <div className="message-board">
                    {dirty && !valid && error === "passwordNotMatch" && (
                      <Container
                        className="text-center negative hint mt10 mb10"
                        style={
                          (dirty && valid) || submitting
                            ? {
                                color: "transparent",
                                lineHeight: "20px",
                              }
                            : { lineHeight: "20px" }
                        }
                      >
                        Sorry, your password does not match.
                        <br />
                        Please check and try again.
                      </Container>
                    )}
                    <Message
                      error
                      negative
                      style={{ paddingTop: "7px", paddingLeft: "35px" }}
                    >
                      <span className="header">Error</span>
                      {error && (
                        <span className="content">
                          <br />
                          {error}
                        </span>
                      )}
                    </Message>
                    <Message
                      success
                      style={{ paddingTop: "7px", paddingLeft: "35px" }}
                    >
                      <span className="header">
                        Your password has been reset successfully
                      </span>
                      <span className="content">
                        <br />
                        You may now Sign-in with your account
                      </span>
                    </Message>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
        <Newsletter />
        <PreFooter />
      </div>,
    ];
  }
}

export default RecoveryPWDPresentational;
