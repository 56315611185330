// @flow
// API Company static class

const apiBaseCompany = "";
export default class Company {
  // Check Invitation Company token
  static CheckToken(api: Object, token: string) {
    api.setToken(token);
    return api.POST(`${apiBaseCompany}/company-invitations/checkToken`, {
      activationToken: token,
    });
  }
  // Check Tenant Available
  static CheckSubdomain(api: Object, subdomain: string) {
    return api.GET(
      `${apiBaseCompany}/tenants/checkSubdomain?subdomain=${subdomain}`
    );
  }
  // Create Tenant
  static CreateTenant(api: Object, subdomain: string) {
    return api.POST(`${apiBaseCompany}/tenants`, {
      subdomain: subdomain,
    });
  }
  // Create Company
  static CreateCompany(api: Object, data: Object) {
    return api.POST(`${apiBaseCompany}/companies`, data);
  }
}
