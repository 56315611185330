// @flow
import * as React from "react";
import { connect } from "react-redux";
import { ServiceWorker } from "../../actions/actions";
import ServiceWorkerPresentational from "./ServiceWorkerPresentational";
import "./ServiceWorker.css";

type Props = {
  refreshButtonShow: boolean,
  onRefreshButtonShow: () => void,
};
type State = {
  counter: number,
  timerId: ?TimeoutID,
};

class ServiceWorkerContainer extends React.Component<Props, State> {
  state = {
    counter: 5,
    timerId: null,
  };
  _onSetCountDown = () => {
    this._onCountDown();
  };
  _onCountDown = () => {
    if (this.state.counter === 0) {
      this.onConfirm();
    } else {
      this.setState({ counter: this.state.counter - 1 });
      const timerId = setTimeout(() => {
        this._onCountDown();
      }, 1000);
      this.setState({ timerId });
    }
  };
  onConfirm = () => {
    const { timerId } = this.state;
    if (timerId) {
      clearTimeout(timerId);
    }
    this.setState({
      counter: 5,
      timerId: null,
    });
    const { onRefreshButtonShow } = this.props;
    onRefreshButtonShow();
    window.location.reload();
  };
  componentDidUpdate(prevProps: Props, prevState: any, snapshot: any) {
    if (this.props.refreshButtonShow) {
      if (document.body) {
        document.body.classList.add("confirm-service-worker");
      }
      if (prevProps.refreshButtonShow !== this.props.refreshButtonShow) {
        if (prevState.counter === 5) {
          this._onSetCountDown();
        }
      }
    } else {
      if (prevProps.refreshButtonShow && document.body) {
        document.body.classList.remove("confirm-service-worker");
      }
    }
  }
  render() {
    const { refreshButtonShow } = this.props;
    if (!refreshButtonShow) {
      return null;
    }
    return (
      <ServiceWorkerPresentational
        onConfirm={this.onConfirm}
        counter={this.state.counter}
      />
    );
  }
}

const bindActions = (dispatch) => {
  return {
    onRefreshButtonShow: () =>
      dispatch(ServiceWorker.serviceWorkerRefreshButtonShow()),
  };
};

export default connect(
  ({ serviceWorker: { refreshButtonShow } }) => ({ refreshButtonShow }),
  bindActions
)(ServiceWorkerContainer);
