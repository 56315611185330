// @flow
import { createAction } from "redux-actions";

// Actions for API
export const INIT_API: string = "API/INIT_API";
export const initAPI = createAction(INIT_API);

export const SET_TOKEN: string = "API/SET_TOKEN";
export const setToken = createAction(SET_TOKEN);

export const UNSET_TOKEN: string = "API/UNSET_TOKEN";
export const unsetToken = createAction(UNSET_TOKEN);
