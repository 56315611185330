// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
import * as React from "react";
import { Field } from "redux-form";
import {
  Button,
  Confirm,
  Dropdown,
  Form,
  Icon,
  Image,
  Input,
  Message,
  Modal,
  Popup,
} from "semantic-ui-react";
import Breadcrumbs from "../shared/Breadcrumbs";
import Newsletter from "../shared/Newsletter";
import PreFooter from "../shared/PreFooter";
import iconX from "../../images/x.svg";

const renderInputField: React.ComponentType<*> = ({
  input,
  errorField,
  setErrorFields,
  placeholder,
  type,
  className,
  meta: { active, error },
}) => (
  <Form.Field>
    <Form.Input
      {...input}
      icon={
        input.value.length > 0 && (
          <Image src={iconX} alt="x" onClick={() => input.onChange("")} />
        )
      }
      onBlur={setErrorFields(
        `error${placeholder}`,
        input.value.length > 0 && error && true
      )}
      onFocus={setErrorFields(`error${placeholder}`, false)}
      className={errorField ? "warning" : className}
      fluid
      placeholder={placeholder}
      type={type}
      error={errorField && input.value.length > 0 && error && true}
    />
  </Form.Field>
);

type Props = {
  companyDomains: Array<string>,
  companyDomain: string,
  companyDomainNotFound: boolean,
  onCompanyDomainToAdd: (event: Object, { value: string }) => void,
  onRemoveCompanyDomain: (companyToDelete: string) => (event: Object) => void,
  baseDomain: string,
  suffix: string,
  error: string,
  errorEmail: boolean,
  errorPassword: boolean,
  errorCompanyDomain: string,
  submitting: boolean,
  valid: boolean,
  onSetCompanyDomain: (event: Object, { field: string, value: string }) => void,
  setErrorFields: Function,
  onSubmit: Function,
  goToRecoveryPWD: Function,
  goToContact: Function,
  isFetchingAccounts: boolean,
  accountsAvailable: Array<Object>,
  accountSelected: number,
  onSetAccount: (event: Object, { value: number }) => void,
  onCloseModalToSubscription: () => void,
  showModalToSubscription: boolean,
  showModalByMaintenance: boolean,
  goToSubscriptions: () => void,
};

const parseName = (name: string) => name.split(/(?=[A-Z])/).join(" ");
const parseApiName = (apiName: string) =>
  apiName.replace(
    /(Company|Companies|Institution|Institutions|Administrators|Faculties|File|Files)/gi,
    (str: string) => {
      switch (str) {
        case "Company":
          return "School";
        case "Companies":
          return "Schools";
        case "Institution":
          return "Clinic";
        case "Institutions":
          return "Clinics";
        case "Administrators":
          return "Admins";
        case "Faculties":
          return "Faculty";
        case "Files":
          return "Documents";
        case "File":
          return "Document";
        default:
          return str;
      }
    }
  );

class SignInPresentational extends React.Component<Props> {
  render() {
    const {
      companyDomain,
      companyDomainNotFound,
      onSubmit,
      submitting,
      setErrorFields,
      error,
      errorEmail,
      errorPassword,
      errorCompanyDomain,
      valid,
      goToRecoveryPWD,
      goToContact,
      onCloseModalToSubscription,
      showModalToSubscription,
      showModalByMaintenance,
      goToSubscriptions,
    } = this.props;
    return [
      <Breadcrumbs
        key="breadcrumbs-signin"
        crumbs={[
          { pathName: "Home", path: "/" },
          { pathName: "Sign In", path: null },
        ]}
      />,
      <div key="content-signin" className="container-fluid body website-signin">
        <section className="input-form mb100">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
              <h1 className="title text-teal text-center website">Sign In</h1>
              <div className="form mt30 modal-create-company signin seriously-fullscreen-mobile">
                <Form
                  loading={submitting}
                  onSubmit={onSubmit}
                  error={error && !submitting}
                >
                  {this.renderFieldCompanyDomain()}
                  <Field
                    name="email"
                    type="email"
                    errorField={errorEmail}
                    setErrorFields={setErrorFields}
                    placeholder="Email"
                    component={renderInputField}
                    className={valid ? "success" : ""}
                  />
                  {this.renderFieldAccountSelected()}
                  <Field
                    name="password"
                    type="password"
                    errorField={errorPassword}
                    setErrorFields={setErrorFields}
                    placeholder="Password"
                    component={renderInputField}
                    className={valid ? "success" : ""}
                  />
                  <div
                    className={`message ${
                      !valid && !errorEmail && !errorPassword
                        ? "info"
                        : "warning"
                    }`}
                    style={!valid ? {} : { opacity: 0 }}
                  >
                    {!errorEmail && !errorPassword
                      ? errorCompanyDomain
                        ? errorCompanyDomain
                        : "Your password must be a minimum of eight characters"
                      : ((errorEmail || errorPassword) &&
                          error &&
                          error.split(/<br\/>/gi).map((el, index) =>
                            index === 0 ? (
                              el
                            ) : (
                              <>
                                <br />
                                {el}
                              </>
                            )
                          )) ||
                        " "}
                  </div>
                  <Button
                    loading={submitting}
                    disabled={!valid || submitting}
                    fluid
                    type="submit"
                    className={`${valid ? "skejule" : ""} text-center mt25`}
                  >
                    Sign In
                  </Button>
                  <div className="message nopadding">
                    <span className="forgotpwd mr10">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={goToRecoveryPWD}
                      >
                        Forgot password?
                      </a>
                    </span>
                    <span className="sign up right">
                      <a style={{ cursor: "pointer" }} onClick={goToContact}>
                        Sign up for Heto
                      </a>
                    </span>
                  </div>
                </Form>
                {companyDomainNotFound && (
                  <div className="message-board mt30">
                    <Message negative style={{ padding: "7px 32px" }}>
                      <span className="header">This School does not exist</span>
                    </Message>
                  </div>
                )}
                {showModalToSubscription && (
                  <Confirm
                    size="small"
                    className="subscription-link nopadding"
                    open={true}
                    content={
                      <div className="header skejule-teal">
                        <h3 className="mt10">School Name: {companyDomain}</h3>
                        <h4 className="mt20 mb10 text-justify">
                          Looks like your student account has expired. However,
                          you can continue to access Heto by subscribing to one
                          of our Alumni plans at a nominal price.
                        </h4>
                      </div>
                    }
                    cancelButton="Cancel"
                    onCancel={onCloseModalToSubscription}
                    closeOnDimmerClick={false}
                    confirmButton={
                      <Button size="medium" className="skejule">
                        Continue
                      </Button>
                    }
                    onConfirm={goToSubscriptions}
                  />
                )}
                {showModalByMaintenance && (
                  <Modal
                    size="small"
                    className="nopadding"
                    open={true}
                    content={
                      <div className="header skejule-teal">
                        <h1 className="mt10">
                          The system is currently under maintenance.
                        </h1>
                        <h3>Please try logging in after 4 hours.</h3>
                        <h3>Sorry for the inconvenience.</h3>
                        <h4 className="text-right">Thank you,</h4>
                        <h4 className="text-right">Heto Support Team.</h4>
                      </div>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        <Newsletter />
        <PreFooter />
      </div>,
    ];
  }
  renderFieldCompanyDomain = () => {
    const {
      baseDomain,
      suffix,
      companyDomains,
      companyDomain,
      onSetCompanyDomain,
      onCompanyDomainToAdd,
      onRemoveCompanyDomain,
    } = this.props;
    if (companyDomains.length > 0) {
      return (
        <div
          field="companyDomain"
          className="ui fluid right labeled input skejule nofloat info"
        >
          <Dropdown
            name="add"
            maxLength="100"
            selectOnBlur={false}
            selection
            search
            fluid
            icon={null}
            options={companyDomains.map((value, index) => ({
              name: "remove",
              key: value + index,
              value: value,
              text: value,
              icon: (
                <Icon
                  name="trash"
                  className="right"
                  onClick={onRemoveCompanyDomain(value)}
                />
              ),
            }))}
            text={companyDomain || "Select School"}
            placeholder="Select School"
            value={companyDomain}
            allowAdditions
            additionLabel={<i className="skejule-teal">Add School: </i>}
            noResultsMessage={
              companyDomains.length === 0 ? "Empty, add ..." : "Not Found"
            }
            onAddItem={onCompanyDomainToAdd}
            onChange={onSetCompanyDomain}
          />
          <div className="ui label label">{`.${baseDomain}${suffix}`}</div>
        </div>
      );
    }
    return (
      <Input
        fluid
        placeholder="School"
        label={`.${baseDomain}${suffix}`}
        labelPosition="right"
        className={"skejule nofloat info"}
        field="companyDomain"
        value={companyDomain}
        onChange={onSetCompanyDomain}
      />
    );
  };
  renderFieldAccountSelected = () => {
    const {
      isFetchingAccounts,
      accountsAvailable,
      accountSelected,
      onSetAccount,
    } = this.props;
    if (accountsAvailable.length > 1) {
      return (
        <div field="accountSelected" className="ui fluid input skejule">
          <Dropdown
            selectOnBlur={false}
            selection
            fluid
            loading={isFetchingAccounts}
            options={accountsAvailable.map(
              ({ role, status, institution }, index) => ({
                key: role + index,
                value: index,
                text: `${parseApiName(parseName(role))} ${
                  institution ? "in " + institution.name : ""
                }`,
                // style: status === "inactive" ? { opacity: 0.5 } : {},
                className: status === "inactive" ? "disabled" : undefined,
                description:
                  status === "inactive" ? (
                    <Popup
                      content="User account is not active. Please contact with your school administrator, if you need this user account to be in active."
                      trigger={
                        <Icon
                          name="warning sign"
                          className="skejule-brown right"
                        />
                      }
                      position="top center"
                    />
                  ) : undefined,
              })
            )}
            text={
              accountSelected >= 0
                ? `${parseApiName(
                    parseName(accountsAvailable[accountSelected].role)
                  )} ${
                    accountsAvailable[accountSelected].institution
                      ? "in " +
                        accountsAvailable[accountSelected].institution.name
                      : ""
                  }`
                : "Select Account"
            }
            placeholder="Select Account"
            onChange={onSetAccount}
            value={accountSelected}
          />
        </div>
      );
    }
    return null;
  };
}

export default SignInPresentational;
