// @flow
// API Users static class

export default class Users {
  // Check Invitation token for Sign Up process
  static CheckToken(api: Object, token: string, tenant: string) {
    api.setToken(token);
    return api.POST(`/user-invitations/checkToken`, {
      activationToken: token,
      tenant,
    });
  }
}
