// @flow
import { Company } from "../actions/actions";
// Company Reducer
type StateCreate = {
  +subdomain: string,
  +companyName: string,
  +account: {
    +email: string,
    +firstName: string,
    +lastName: string,
  },
  +stepsAllowed: {
    +invitation: boolean,
    +account: boolean,
    +password: boolean,
    +company: boolean,
    +done: boolean,
  },
};
type State = {
  +companyDomains: Array<string>,
  +companyDomainSelected: string,
  +create: StateCreate,
};
type Action = {
  +type: string,
  +payload: Object,
};

const initialStateCreate: StateCreate = {
  subdomain: "",
  companyName: "",
  account: {
    email: "",
    firstName: "",
    lastName: "",
  },
  //It is needed to follow right steps else redirect to index
  stepsAllowed: {
    invitation: true,
    company: false,
    account: false,
    password: false,
    done: false,
  },
};

const initialState: State = {
  companyDomains: [],
  companyDomainSelected: "",
  create: initialStateCreate,
};

export default function company(
  state: State = initialState,
  action: Action
): State {
  let create: Object;
  switch (action.type) {
    case Company.COMPANY_SELECTED_COMPANY_DOMAIN:
      return Object.assign({}, state, {
        companyDomainSelected: action.payload.companyDomain,
      });
    case Company.COMPANY_SELECTED_COMPANY_DOMAIN_SIGN_IN_SUCCESS:
      return Object.assign({}, state, {
        companyDomains:
          state.companyDomains.indexOf(state.companyDomainSelected) === -1
            ? [...state.companyDomains, state.companyDomainSelected]
            : state.companyDomains,
      });
    case Company.COMPANY_REMOVE_COMPANY_DOMAIN:
      return Object.assign({}, state, {
        companyDomains: state.companyDomains.filter(
          (companyToFind) => companyToFind !== action.payload.companyDomain
        ),
      });
    case Company.COMPANY_CREATE_CHECK_INVITATION_SUCCESS:
      create = Object.assign({}, state.create, {
        account: {
          email: action.payload.email,
          firstName: "",
          lastName: "",
        },
        stepsAllowed: {
          invitation: true,
          company: true,
          account: false,
          password: false,
          done: false,
        },
      });
      return Object.assign({}, state, { create: create });
    case Company.COMPANY_CREATE_TENANT_SUCCESS:
      create = Object.assign({}, state.create, {
        subdomain: action.payload.subdomain,
        companyName: action.payload.companyName,
        stepsAllowed: {
          invitation: true,
          company: true,
          account: true,
          password: false,
          done: false,
        },
      });
      return Object.assign({}, state, { create: create });
    case Company.SET_ACCOUNT:
      create = Object.assign({}, state.create, {
        account: {
          email: action.payload.email,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
        stepsAllowed: {
          invitation: true,
          company: true,
          account: true,
          password: true,
          done: false,
        },
      });
      return Object.assign({}, state, { create: create });
    case Company.COMPANY_CREATE_SUCCESS:
      create = Object.assign({}, state.create, {
        stepsAllowed: {
          invitation: true,
          company: true,
          account: true,
          password: true,
          done: true,
        },
      });
      return Object.assign({}, state, { create: create });
    // initial state
    case Company.COMPANY_CREATE_DONE:
      return Object.assign({}, state, { create: initialStateCreate });
    // same state (not change)
    default:
      return state;
  }
}
