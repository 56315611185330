// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PublicUrlContainer from "./PublicUrlContainer";
import ProtectedRoute from "../shared/ProtectedRoute";
const PublicUrlRoute: React.ComponentType<any> = withRouter(
  connect(({ config }, ownProps) => {
    const { params } = ownProps.computedMatch;
    const { url, tenant, dataId } = params;
    return {
      component: PublicUrlContainer,
      restProps: { url, tenant, dataId, config },
      isAllowedRoute: url && tenant && dataId,
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default PublicUrlRoute;
