// @flow
import { createAction } from "redux-actions";

//Contact Form
export const CONTACT_FORM: string = "CONTACT/FORM";
export const CONTACT_FORM_REQUEST: string = "CONTACT/FORM/REQUEST";
export const CONTACT_FORM_SUCCESS: string = "CONTACT/FORM/SUCCESS";
export const CONTACT_FORM_FAILURE: string = "CONTACT/FORM/FAILURE";
export const contactForm = createAction(CONTACT_FORM);
export const contactFormRequest = createAction(CONTACT_FORM_REQUEST);
export const contactFormSuccess = createAction(CONTACT_FORM_SUCCESS);
export const contactFormFailure = createAction(CONTACT_FORM_FAILURE);
