// @flow
import * as React from "react";
import { Button, Confirm } from "semantic-ui-react";

type Props = {
  counter: number,
  onConfirm: () => void,
};

class ServiceWorkerPresentational extends React.Component<Props> {
  render() {
    const { counter, onConfirm } = this.props;
    return (
      <Confirm
        size="small"
        className="service-worker-button nopadding"
        open={true}
        content={
          <div className="header skejule-teal">
            <h3>New content is available. Heto will refresh in ...</h3>
            <h3>{counter}</h3>
          </div>
        }
        cancelButton={null}
        closeOnDimmerClick={false}
        confirmButton={
          <Button size="medium" className="skejule p10 mb0">
            <span className="label">Refresh</span>
          </Button>
        }
        onConfirm={onConfirm}
      />
    );
  }
}

export default ServiceWorkerPresentational;
