// @flow
// Import Features
import * as React from "react";
// Import Components
import { Icon, Image, Message, Modal } from "semantic-ui-react";
import ErrorComponent from "../shared/ErrorComponent";
// Import images
import waitingForProcess from "../../images/waiting-for-process.svg";
// Types
type Props = {
  checkingPublicUrl: boolean,
  errorPublicUrl: string,
};
class PublicUrlPresentational extends React.Component<Props> {
  render() {
    const { checkingPublicUrl, errorPublicUrl } = this.props;
    return (
      <div id="main" className="activate-account">
        <Modal
          className="modal-create-company modal-activation-account seriously-fullscreen-mobile"
          dimmer={false}
          open={true}
          size={"small"}
        >
          <Modal.Header>
            <Modal.Header>
              {checkingPublicUrl && <h4>Loading ...</h4>}
            </Modal.Header>
          </Modal.Header>
          {checkingPublicUrl ? (
            <Modal.Content>
              <Image
                src={waitingForProcess}
                className="illustration mb50 mt50"
                alt="illustration-empty"
                centered
              />
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  We are loading the information.
                </Message.Content>
              </Message>
            </Modal.Content>
          ) : (
            <Modal.Content>
              {errorPublicUrl && (
                <ErrorComponent
                  className="activation-account-error"
                  errorMessageHeader="Public URL is not valid, check your url please."
                  errorMessageContent={errorPublicUrl}
                />
              )}
            </Modal.Content>
          )}
        </Modal>
      </div>
    );
  }
}
export default PublicUrlPresentational;
