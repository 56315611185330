// @flow
import * as React from "react";
import { Route, Redirect } from "react-router-dom";
// Component generic to manage routes protected.
// * component - React Component
// * restProps - inject our Props
// * isAllowedRoute -- boolean
// * redirectTo - location descriptor
const ProtectedRoute = ({
  component: Component,
  restProps,
  isAllowedRoute,
  redirectTo,
  ...rest
}: Object): React$Element<any> => (
  <Route
    {...rest}
    render={(props) =>
      isAllowedRoute ? (
        Component ? (
          <Component {...props} {...restProps} />
        ) : null
      ) : (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default ProtectedRoute;
