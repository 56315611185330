// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HomeContainer from "./HomeContainer";
import ProtectedRoute from "../shared/ProtectedRoute";

const HomeRoute: React.ComponentType<any> = withRouter(
  connect(() => {
    return {
      component: HomeContainer,
      restProps: null,
      isAllowedRoute: true,
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default HomeRoute;
