// @flow
import { createAction } from "redux-actions";

// Actions for company

// Create Company:
// Check Invitation
export const COMPANY_CREATE_CHECK_INVITATION: string =
  "COMPANY/CREATE/CHECK/INVITATION";
export const COMPANY_CREATE_CHECK_INVITATION_REQUEST: string =
  "COMPANY/CREATE/CHECK/INVITATION/REQUEST";
export const COMPANY_CREATE_CHECK_INVITATION_SUCCESS: string =
  "COMPANY/CREATE/CHECK/INVITATION/SUCCESS";
export const COMPANY_CREATE_CHECK_INVITATION_FAILURE: string =
  "COMPANY/CREATE/CHECK/INVITATION/FAILURE";
export const companyCreateCheckInvitation = createAction(
  COMPANY_CREATE_CHECK_INVITATION
);
export const companyCreateCheckInvitationRequest = createAction(
  COMPANY_CREATE_CHECK_INVITATION_REQUEST
);
export const companyCreateCheckInvitationSuccess = createAction(
  COMPANY_CREATE_CHECK_INVITATION_SUCCESS
);
export const companyCreateCheckInvitationFailure = createAction(
  COMPANY_CREATE_CHECK_INVITATION_FAILURE
);
// Check Subdomain (Tenant available)
export const COMPANY_CREATE_CHECK_SUBDOMAIN: string =
  "COMPANY/CREATE/CHECK_SUBDOMAIN";
export const COMPANY_CREATE_CHECK_SUBDOMAIN_REQUEST: string =
  "COMPANY/CREATE/CHECK_SUBDOMAIN/REQUEST";
export const COMPANY_CREATE_CHECK_SUBDOMAIN_SUCCESS: string =
  "COMPANY/CREATE/CHECK_SUBDOMAIN/SUCCESS";
export const COMPANY_CREATE_CHECK_SUBDOMAIN_FAILURE: string =
  "COMPANY/CREATE/CHECK_SUBDOMAIN/FAILURE";
export const companyCreateCheckSubdomain = createAction(
  COMPANY_CREATE_CHECK_SUBDOMAIN
);
export const companyCreateCheckSubdomainRequest = createAction(
  COMPANY_CREATE_CHECK_SUBDOMAIN_REQUEST
);
export const companyCreateCheckSubdomainSuccess = createAction(
  COMPANY_CREATE_CHECK_SUBDOMAIN_SUCCESS
);
export const companyCreateCheckSubdomainFailure = createAction(
  COMPANY_CREATE_CHECK_SUBDOMAIN_FAILURE
);
// Create Tenant
export const COMPANY_CREATE_TENANT: string = "COMPANY/CREATE/TENANT";
export const COMPANY_CREATE_TENANT_REQUEST: string =
  "COMPANY/CREATE/TENANT/REQUEST";
export const COMPANY_CREATE_TENANT_SUCCESS: string =
  "COMPANY/CREATE/TENANT/SUCCESS";
export const COMPANY_CREATE_TENANT_FAILURE: string =
  "COMPANY/CREATE/TENANT/FAILURE";
export const companyCreateTenant = createAction(COMPANY_CREATE_TENANT);
export const companyCreateTenantRequest = createAction(
  COMPANY_CREATE_TENANT_REQUEST
);
export const companyCreateTenantSuccess = createAction(
  COMPANY_CREATE_TENANT_SUCCESS
);
export const companyCreateTenantFailure = createAction(
  COMPANY_CREATE_TENANT_FAILURE
);
// Set Account (save account information to send at end process)
export const SET_ACCOUNT: string = "COMPANY/CREATE/SET_ACCOUNT";
export const setAccount = createAction(SET_ACCOUNT);
// Create Company
export const COMPANY_CREATE: string = "COMPANY/CREATE";
export const COMPANY_CREATE_REQUEST: string = "COMPANY/CREATE/REQUEST";
export const COMPANY_CREATE_SUCCESS: string = "COMPANY/CREATE/SUCCESS";
export const COMPANY_CREATE_FAILURE: string = "COMPANY/CREATE/FAILURE";
export const COMPANY_CREATE_DONE: string = "COMPANY/CREATE/DONE";
export const companyCreate = createAction(COMPANY_CREATE);
export const companyCreateRequest = createAction(COMPANY_CREATE_REQUEST);
export const companyCreateSuccess = createAction(COMPANY_CREATE_SUCCESS);
export const companyCreateFailure = createAction(COMPANY_CREATE_FAILURE);
export const companyCreateDone = createAction(COMPANY_CREATE_DONE);
// Create Company END />

// Selected Company Domain (It is to do sign in)
export const COMPANY_SELECTED_COMPANY_DOMAIN: string =
  "COMPANY/SELECTED/COMPANY_DOMAIN";
export const companySelectedCompanyDomain = createAction(
  COMPANY_SELECTED_COMPANY_DOMAIN
);
// Selected Company Domain Sign In Succes (It is to do sign in)
export const COMPANY_SELECTED_COMPANY_DOMAIN_SIGN_IN_SUCCESS: string =
  "COMPANY/SELECTED/COMPANY_DOMAIN/SIGNIN/SUCCESS";
export const companySelectedCompanyDomainSignInSuccess = createAction(
  COMPANY_SELECTED_COMPANY_DOMAIN_SIGN_IN_SUCCESS
);
// Remove Company from companyDomains Array (It is to do sign in)
export const COMPANY_REMOVE_COMPANY_DOMAIN: string =
  "COMPANY/REMOVE/COMPANY_DOMAIN";
export const companyRemoveCompanyDomain = createAction(
  COMPANY_REMOVE_COMPANY_DOMAIN
);
