// @flow
// API Auth static class

const apiBaseAuth = "/users";
export default class Auth {
  // Get Accounts (required for Sign In)
  static GetAccounts(api: Object, body: Object) {
    return api.POST(`${apiBaseAuth}/accounts`, body);
  }

  // Sign in
  static SignIn(api: Object, credentials: Object) {
    return api.POST(`${apiBaseAuth}/signin`, credentials);
  }

  // Sign out
  static SignOut(api: Object) {
    return api.GET(`${apiBaseAuth}/signout`);
  }

  // Sign up
  static SignUp(api: Object, body: Object) {
    return api.POST(`${apiBaseAuth}`, body);
  }
  // Activation Account
  static ActivateAccount(api: Object, body: Object) {
    return api.PUT(`${apiBaseAuth}/activateAccount`, body);
  }

  // Recovery Password
  static RecoveryPWD(api: Object, body: Object) {
    return api.POST(`${apiBaseAuth}/recoverPassword`, body);
  }

  // Reset Password
  static ResetPWD(api: Object, body: Object) {
    return api.POST(`${apiBaseAuth}/resetPassword`, body);
  }
}
