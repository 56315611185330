// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { scroller } from "react-scroll";
import logo from "../../images/logoheto.png";
import linkedin from "../../images/social-linkedin.svg";
import twitter from "../../images/social-twitter.svg";
import facebook from "../../images/social-facebook.svg";
// Component Pre Footer.

const bindActions = (dispatch) => {
  return {
    goTo: (path) => dispatch(push(path)),
  };
};

const PreFooter: React.ComponentType<any> = connect(
  null,
  bindActions
)((props): React$Element<any> => {
  const { goTo } = props;
  const onChangeMenu = (path) => () => {
    goTo(path);
    if (path === "/#what") {
      scroller.scrollTo("myScrollToWhat", {
        duration: 1500,
        delay: 100,
        smooth: true,
      });
    }
    if (path === "/#features") {
      scroller.scrollTo("myScrollToFeatures", {
        duration: 1500,
        delay: 100,
        smooth: true,
      });
    }
  };

  return (
    <section className="prefooter container">
      <div className="row">
        <div className="col-xs-8 col-xs-offset-2 col-sm-3 col-sm-offset-0">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-0">
          <div className="row">
            <div className="col-sm-6">
              <div className="sitemap right">
                <ul>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={onChangeMenu("/#what")}
                    >
                      What is Heto?
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={onChangeMenu("/#features")}
                    >
                      Features
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="sitemap left">
                <ul>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={onChangeMenu("/about")}
                    >
                      About us
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={onChangeMenu("/contact")}
                    >
                      Get in touch
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-3">
          <div className="followus">Follow us</div>
          <div className="social-networks">
            {[
              {
                name: "linkedIn",
                url: "https://www.linkedin.com/company/cabaana",
                src: linkedin,
              },
              {
                name: "twitter",
                url: "https://www.twitter.com/heto_online",
                src: twitter,
              },
              {
                name: "facebook",
                url: "https://www.facebook.com/cabaanainc",
                src: facebook,
              },
            ].map((social) => {
              return (
                <a
                  key={social.name}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer external"
                >
                  <img
                    className="right ml20"
                    src={social.src}
                    alt={social.name}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
});

export default PreFooter;
