// @flow
import { put, select, call, all, fork, takeLatest } from "redux-saga/effects";
import UsersApi from "../api/Users";
import { Api, Users } from "../actions/actions";

//Sagas for users

function* doUsersSignUpCheckInvitation(action) {
  yield put(Users.usersSignUpCheckInvitationRequest());
  window.logger.log("doUsersSignUpCheckInvitation", action);
  const { subdomain, token, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to check token for this invitation
    const { data } = yield call(UsersApi.CheckToken, api, token, subdomain);
    const { email, token: jwToken, multipleUser } = data;
    let firstName = "",
      lastName = "";
    if (multipleUser) {
      firstName = multipleUser.firstName;
      lastName = multipleUser.lastName;
    }
    yield put(Api.setToken({ url: api.getBaseUrl(), token: jwToken }));
    yield put(
      Users.usersSignUpCheckInvitationSuccess({
        email: email,
        firstName,
        lastName,
      })
    );
    callbackSuccess();
  } catch (error) {
    window.logger.log("ErrorSaga", error);
    yield put(Users.usersSignUpCheckInvitationFailure());
    callbackError(error);
  }
}

export default function* usersSagas(): Generator<*, *, *> {
  yield all([
    fork(
      takeLatest,
      Users.USERS_SIGN_UP_CHECK_INVITATION,
      doUsersSignUpCheckInvitation
    ),
  ]);
}
