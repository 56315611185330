// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
import * as React from "react";
import logo from "../../images/logoheto.png";
import userSignin from "../../images/signin.svg";

type Props = {
  activeMenu: string,
  onChangeMenu: Function,
  openMobileMenu: boolean,
  onOpenMobileMenu: Function,
};
class MenuPresentational extends React.Component<Props> {
  render() {
    const {
      activeMenu,
      onChangeMenu,
      openMobileMenu,
      onOpenMobileMenu,
    } = this.props;
    return (
      <nav className="navbar navbar-default navbar-static-top">
        <div className="container">
          <div className="col-sm-2">
            <div className="navbar-header">
              <div className="row">
                <button
                  type="button"
                  className={`navbar-toggle ${
                    openMobileMenu ? "" : "collapsed"
                  }`}
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded={openMobileMenu}
                  onClick={onOpenMobileMenu}
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>

                <div className="col-xs-12">
                  {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                  <a
                    className="navbar-brand img-responsive"
                    style={activeMenu !== "/" ? { cursor: "pointer" } : {}}
                    onClick={onChangeMenu("/")}
                  >
                    <img src={logo} alt="logo" className="logo" />
                  </a>
                </div>
                <button
                  className="signin text-teal right visible-xs hidden-sm hidden-md hidden-lg"
                  onClick={onChangeMenu("/signin")}
                >
                  <img src={userSignin} alt="go-to-signin" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-10">
            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
            <div
              className={`navbar-collapse collapse ${
                openMobileMenu ? "in" : ""
              } ml15`}
              id="bs-example-navbar-collapse-1"
              aria-expanded={openMobileMenu}
            >
              <ul className="nav navbar-nav">
                <li className={activeMenu === "/#what" ? "active" : ""}>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={onChangeMenu("/#what")}
                  >
                    What is Heto
                  </a>
                </li>
                <li className={activeMenu === "/#features" ? "active" : ""}>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={onChangeMenu("/#features")}
                  >
                    Features
                  </a>
                </li>
                <li className={activeMenu === "/about" ? "active" : ""}>
                  <a
                    style={activeMenu !== "/about" ? { cursor: "pointer" } : {}}
                    onClick={onChangeMenu("/about")}
                  >
                    About us
                  </a>
                </li>
                <li className={activeMenu === "/contact" ? "active" : ""}>
                  <a
                    style={
                      activeMenu !== "/contact" ? { cursor: "pointer" } : {}
                    }
                    onClick={onChangeMenu("/contact")}
                  >
                    Get in touch
                  </a>
                </li>
                <li className="visible-xs visible-sm hidden-md hidden-lg">
                  {(activeMenu === "/" ||
                    activeMenu === "/#what" ||
                    activeMenu === "/#features" ||
                    activeMenu === "/legal" ||
                    activeMenu === "/contact" ||
                    activeMenu === "/about") && (
                    <a onClick={onChangeMenu("/signin")}>Sign In</a>
                  )}
                </li>
              </ul>
              {(activeMenu === "/" ||
                activeMenu === "/#what" ||
                activeMenu === "/#features" ||
                activeMenu === "/legal" ||
                activeMenu === "/contact" ||
                activeMenu === "/about") && (
                <button
                  className="signin text-teal right ml30 uppercase hidden-xs hidden-sm visible-md visible-lg website"
                  onClick={onChangeMenu("/signin")}
                >
                  Sign In
                </button>
              )}
            </div>
            {/* <!-- /.navbar-collapse --> */}
          </div>
        </div>
        {/* <!-- /.container --> */}
      </nav>
    );
  }
}

export default MenuPresentational;
