// @flow
import * as React from "react";
// Assets
import AppleStore from "../../images/AppleStore.png";
import GoogleStore from "../../images/GoogleStore.png";
// Mobile Stores: Apple - Android.
const MobileStores = ({
  className,
  textLoading,
}: Object): React$Element<any> => (
  <section className="mobile-stores">
    <section className="container">
      <div className="row mt20">
        <div className="col-sm-12">
          <h1 className="title website">
            Download Heto for Dentistry{" "}
            <i aria-hidden="true" className="download icon" />
          </h1>
        </div>
      </div>
      <div className="row p50 pt30">
        <div className="col-sm-6">
          <div className="icon img-responsive">
            <a
              href="https://apps.apple.com/us/app/heto-for-dentistry/id1505538026"
              target="_blank"
              rel="noopener noreferrer external"
            >
              <img src={AppleStore} alt="Heto for Dentistry for iOS" />
            </a>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="icon img-responsive">
            <a
              href="https://play.google.com/store/apps/details?id=com.heto.app"
              target="_blank"
              rel="noopener noreferrer external"
            >
              <img src={GoogleStore} alt="Heto for Dentistry for Android" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </section>
);
export default MobileStores;
