// @flow
// API Public UrL static class
const apiBasePublicUrl = "/p";
export default class PublicUrl {
  // Get Data for some token
  static GetPublicUrlDatas(
    api: Object,
    url: "metric-pages" | "portfolios",
    tenant: string,
    dataId: string
  ) {
    return api.GET(`/${url}${apiBasePublicUrl}/${tenant}/${dataId}`);
  }
}
