// @flow
import * as React from "react";
import { Field } from "redux-form";
import { Button, Message } from "semantic-ui-react";
import Phone from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import Breadcrumbs from "../shared/Breadcrumbs";
import PreFooter from "../shared/PreFooter";

type Props = {
  isRecaptchaValid: boolean,
  errorSuccess: "" | "Loading" | "Error" | "Success",
  invalid: boolean,
  renderCaptcha: () => void,
  onSubmit: (payload: any) => void,
  onDismiss: () => void,
};

const renderInputField: React.ComponentType<*> = ({
  input,
  id,
  type,
  className,
  placeholder,
  InputTextArea,
}) => (
  <InputTextArea
    {...input}
    id={id}
    type={type}
    className={className}
    placeholder={placeholder}
  />
);

const renderPhoneField: React.ComponentType<*> = ({ input }) => (
  <Phone
    className="row row-telephone heto-contact-phone mt20"
    country="US"
    flags={flags}
    onChange={(value) => input.onChange(value || "")}
    value={input.value}
    placeholder="Phone Number"
  />
);

class ContactPresentational extends React.Component<Props> {
  render() {
    const {
      isRecaptchaValid,
      renderCaptcha,
      errorSuccess,
      invalid,
      onSubmit,
      onDismiss,
    } = this.props;
    return [
      <Breadcrumbs
        key="breadcrumbs-contact"
        crumbs={[
          { pathName: "Home", path: "/" },
          { pathName: "Contact Us", path: null },
        ]}
      />,
      <div
        key="content-contact"
        className="container body nopadding website-contact"
      >
        <section className="contact">
          <div className="row">
            <div className="col-sm-6">
              <div className="pl25 pr25">
                <h1 className="title text-teal website">Demo Request</h1>
                <div className="col-xs-3 barseparator mt5" />
              </div>
              <div className="p50">
                Please fill in the accompanied form if you are interested in
                seeing a demo of our product.
                <br />
                <br />
                Our able customer support team will be in touch with you to
                schedule a demo at your convenience.
                <br />
                <br />
                Our informal approach to introducing you to Heto will ensure
                that you are not overwhelmed when you see the solution.
                <br />
                <br />
                Take control of your efficiency. Give Heto a chance & improve
                the way with which you administer your programs.
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form pt0">
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <label htmlFor="name" className="hidden">
                      First Name
                    </label>
                    <Field
                      name="firstName"
                      type="text"
                      className="form-control"
                      id="firstName"
                      placeholder="First Name"
                      InputTextArea="input"
                      component={renderInputField}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastname" className="hidden">
                      Last Name
                    </label>
                    <Field
                      name="lastName"
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder="Last Name"
                      InputTextArea="input"
                      component={renderInputField}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="hidden">
                      Email
                    </label>
                    <Field
                      name="email"
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      InputTextArea="input"
                      component={renderInputField}
                    />
                  </div>
                  <div className="form-group">
                    <Field name="contactPhone" component={renderPhoneField} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message" className="hidden">
                      Message
                    </label>
                    <Field
                      name="message"
                      type="text"
                      className="form-control"
                      id="message"
                      placeholder="Message ..."
                      InputTextArea="textarea"
                      component={renderInputField}
                    />
                  </div>
                  <div className="form-group">{renderCaptcha()}</div>
                  <Button
                    type="submit"
                    className="btn btn-default"
                    style={
                      !isRecaptchaValid || invalid
                        ? { cursor: "default", opacity: 0.5 }
                        : {}
                    }
                    loading={errorSuccess === "Loading"}
                  >
                    Submit
                  </Button>
                  {(errorSuccess === "Success" || errorSuccess === "Error") && (
                    <Message
                      onDismiss={onDismiss}
                      success={errorSuccess === "Success"}
                      error={errorSuccess === "Error"}
                      className="p10"
                      header={errorSuccess}
                      content={
                        errorSuccess === "Success"
                          ? "Form sent successfully"
                          : "Contact Form have not been sent. Try again ?"
                      }
                    />
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
        <PreFooter />
      </div>,
    ];
  }
}

export default ContactPresentational;
