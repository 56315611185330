// @flow
//Heto Redux Store
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import createHistory from "history/createBrowserHistory";
import createSagaMiddleware from "redux-saga";
import freeze from "redux-freeze";
import { reducers } from "./reducers/reducers";
import { sagas } from "./sagas/sagas";
import { Router, Api, Company, LocalStoreActions } from "./actions/actions";
import { LocalStore } from "./utils/storage/localStore";

// Create a browser history
const history: any = createHistory();

const beforeTransitionsMiddleware = (store) => (next) => (action) => {
  if (action.type === "@@router/LOCATION_CHANGE") {
    const { location } = store.getState().router;
    if (location) {
      store.dispatch(Router.routerBeforeTransitions(location));
    } else {
      store.dispatch(Router.routerBeforeTransitions(action.payload));
    }
  }
  return next(action);
};

const localStoreMiddleware = (store) => (next) => (action) => {
  const nextAction = next(action);
  if (action.type === Company.COMPANY_SELECTED_COMPANY_DOMAIN_SIGN_IN_SUCCESS) {
    const { company } = store.getState();
    store.dispatch(
      LocalStoreActions.saveLocalStore({
        key: "companyState",
        value: { company },
      })
    );
  }
  return nextAction;
};

const configureStore = (): Object => {
  // Add the saga middleware
  const sagaMiddleware: any = createSagaMiddleware();
  // Add the router middleware
  // Build the middleware for intercepting and dispatching navigation actions
  let middlewares: Array<mixed> = [
    routerMiddleware(history),
    sagaMiddleware,
    beforeTransitionsMiddleware,
    localStoreMiddleware,
  ];
  let composeEnhancers: Function = compose;
  // Add freeze and redux dev tools, only production
  if (process.env.REACT_APP_ENV !== "prod") {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    middlewares.push(freeze);
  }
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  let preloadedState = {};
  const companyState: Object | null = LocalStore.Read("companyState");
  if (companyState) {
    const { company } = companyState;
    preloadedState = {
      ...preloadedState,
      company,
    };
  }
  const store: Object = createStore(reducers, preloadedState, enhancer);
  sagaMiddleware.run(sagas);
  const { protocol, tenant, base, version } = store.getState().config.api;
  store.dispatch(
    Api.initAPI({ url: `${protocol}${tenant}${base}/${version}`, token: "" })
  );
  return store;
};

// export
export { configureStore, history };
