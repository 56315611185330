// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DownloadFileContainer from "./DownloadFileContainer";
import ProtectedRoute from "../shared/ProtectedRoute";
const DownloadFileRoute: React.ComponentType<any> = withRouter(
  connect(({ config }, ownProps) => {
    const { params } = ownProps.computedMatch;
    const { from, tenant, token, fileId, fileName } = params;
    return {
      component: DownloadFileContainer,
      restProps: { ...params, config },
      isAllowedRoute:
        (from === "ios" || from === "android") &&
        tenant &&
        token &&
        fileId &&
        fileName,
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default DownloadFileRoute;
