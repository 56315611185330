// @flow
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { reduxForm, SubmissionError, formValueSelector } from "redux-form";
import debounce from "lodash/debounce";
import { Company, Auth } from "../../actions/actions";
import RecoveryPWDPresentational from "./RecoveryPWDPresentational";

type Props = {
  getAccounts: (payload: Object) => void,
  location: Object,
  config: {
    protocol: string,
    websitename: string,
    baseDomain: string,
    port: string,
    suffix: string,
  },
  companyDomains: Array<string>,
  companyDomainSelected: string,
  emailSelected: string,
  error: string,
  submitting: boolean,
  valid: boolean,
  handleSubmit: Function,
  onRemoveCompanyDomain: (tenant: string) => void,
  onSelectedCompanyDomain: (tenant: string) => void,
  onRecoveryPWD: Function,
  goTo: Function,
  reset: Function,
};

type State = {
  errorSuccess: string,
  companyDomainNotFound: boolean,
  companyDomainsToAdd: Array<string>,
  isFetchingAccounts: boolean,
  accountsAvailable: Array<Object>,
  accountSelected: number,
};

class RecoveryPWDContainer extends React.Component<Props, State> {
  state = {
    errorSuccess: "",
    companyDomainNotFound: false,
    companyDomainsToAdd: [],
    isFetchingAccounts: false,
    accountsAvailable: [],
    accountSelected: -1,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location.state && this.props.location.state.companyDomain) {
      this.onSelectedCompanyDomain(this.props.location.state.companyDomain);
    }
    if (document.body && document.body.className) {
      document.body.className = "website";
    }
  }
  goToSignIn = () => {
    const { goTo } = this.props;
    goTo("/signin");
  };
  onSubmit = () => {
    const { onRecoveryPWD, companyDomainSelected, goTo } = this.props;
    const { accountsAvailable, accountSelected } = this.state;
    this.setState({
      errorSuccess: "",
    });
    return new Promise((resolve, reject) => {
      const formValues = {
        user:
          accountsAvailable.length > 0 && accountSelected >= 0
            ? accountsAvailable[accountSelected].user
            : undefined,
      };
      const callbacks = {
        callbackError: (msg) => {
          window.logger.log("errors", msg);
          if (
            msg.indexOf("Operation forbidden on the tenant, must exist") > -1
          ) {
            this.tenantNotFound();
            resolve();
            return;
          }
          reject(
            new SubmissionError({
              _error: msg
                ? msg
                : `Fail when sign in for ${companyDomainSelected}. Try Again.`,
            })
          );
          this.setState({
            errorSuccess: "error",
          });
        },
        callbackSuccess: () => {
          window.logger.log("Success - Recovery PWD");
          this.setState({
            errorSuccess: "success",
          });
          setTimeout(() => {
            goTo("/signin");
          }, 5000);
          resolve();
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      onRecoveryPWD(payload);
    });
  };
  onCompanyDomainToAdd = (event, { value }) => {
    // eslint-disable-next-line
    const tenant = value.replace(/[^\w\-]/gi, "").toLowerCase();
    window.logger.log("onCompanyDomainToAdd", tenant);
    this.setState({
      companyDomainsToAdd: [...this.state.companyDomainsToAdd, tenant],
    });
  };
  onRemoveCompanyDomain = (companyToDelete: string) => (event: Object) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const {
      companyDomains,
      onRemoveCompanyDomain,
      companyDomainSelected,
    } = this.props;
    if (companyDomains.indexOf(companyToDelete) !== -1) {
      onRemoveCompanyDomain(companyToDelete);
    }
    if (companyDomainSelected === companyToDelete) {
      this.onSelectedCompanyDomain("");
    }
    this.setState({
      companyDomainsToAdd: this.state.companyDomainsToAdd.filter(
        (companyToFind) => companyToFind !== companyToDelete
      ),
    });
  };
  onSetCompanyDomain = (event, { field, value }) => {
    window.logger.log("onSetCompanyDomain", value);
    // eslint-disable-next-line
    this.onSelectedCompanyDomain(value.replace(/[^\w\-]/gi, "").toLowerCase());
  };
  onSelectedCompanyDomain = (tenant) => {
    window.logger.log("onSelectedCompanyDomain", tenant);
    const {
      onSelectedCompanyDomain,
      companyDomainSelected,
      emailSelected,
    } = this.props;
    if (companyDomainSelected !== tenant) {
      onSelectedCompanyDomain(tenant);
      if (tenant && emailSelected) {
        this.onCheckGetAccount();
      }
    }
  };
  tenantNotFound = () => {
    this.setState({ companyDomainNotFound: true });
    setTimeout(() => {
      this.setState({ companyDomainNotFound: false });
    }, 5000);
  };
  componentDidUpdate(prevProps: Props) {
    if (
      (this.props.emailSelected !== prevProps.emailSelected &&
        this.props.companyDomainSelected) ||
      (this.props.companyDomainSelected !== prevProps.companyDomainSelected &&
        this.props.emailSelected)
    ) {
      this.onCheckGetAccount();
    }
  }
  onCheckGetAccount = () => {
    const { companyDomainSelected, emailSelected } = this.props;
    if (
      companyDomainSelected &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+?\.?[A-Z]{2,4}$/i.test(emailSelected)
    ) {
      this._onGetAccounts({
        email: emailSelected,
        tenant: companyDomainSelected,
      });
    }
  };
  _onGetAccounts = debounce((body: { email: string, tenant: string }) => {
    this.setState({ isFetchingAccounts: true });
    this._onGettingAccounts(body).catch((error) => {
      window.logger.log("onGetAccounts", error);
    });
  }, 500);
  _onGettingAccounts = (body: { email: string, tenant: string }) => {
    const { getAccounts } = this.props;
    return new Promise((resolve, reject) => {
      const formValues = {
        body,
      };
      const callbacks = {
        callbackError: (error) => {
          window.logger.log("error - onGettingAccounts", error);
          this.setState({ isFetchingAccounts: false });
          if (
            error.indexOf("Operation forbidden on the tenant, must exist") > -1
          ) {
            this.tenantNotFound();
            resolve();
            return;
          }
          reject();
        },
        callbackSuccess: (accountsAvailable) => {
          window.logger.log("success - onGettingAccounts");
          this.setState({
            isFetchingAccounts: false,
            accountsAvailable,
            accountSelected: accountsAvailable.length > 0 ? 0 : -1,
          });
          resolve();
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      getAccounts(payload);
    });
  };
  onSetAccount = (
    event: Object,
    { value: accountSelected }: { value: number }
  ) =>
    this.setState({
      accountSelected,
    });
  render() {
    const {
      companyDomains,
      companyDomainSelected,
      config,
      error,
      handleSubmit,
      reset,
      submitting,
      valid,
    } = this.props;
    const {
      errorSuccess,
      companyDomainsToAdd,
      companyDomainNotFound,
      isFetchingAccounts,
      accountsAvailable,
      accountSelected,
    } = this.state;
    return (
      <RecoveryPWDPresentational
        {...config}
        companyDomains={[...companyDomains, ...companyDomainsToAdd]}
        companyDomain={companyDomainSelected}
        onSetCompanyDomain={this.onSetCompanyDomain}
        onCompanyDomainToAdd={this.onCompanyDomainToAdd}
        onRemoveCompanyDomain={this.onRemoveCompanyDomain}
        companyDomainNotFound={companyDomainNotFound}
        onSubmit={handleSubmit(this.onSubmit)}
        errorSuccess={errorSuccess}
        error={error}
        submitting={submitting}
        valid={!isFetchingAccounts && (companyDomainSelected || false) && valid}
        reset={reset}
        goToSignIn={this.goToSignIn}
        isFetchingAccounts={isFetchingAccounts}
        accountsAvailable={accountsAvailable}
        accountSelected={accountSelected}
        onSetAccount={this.onSetAccount}
      />
    );
  }
}

// decorate the form component
const RecoveryPWDForm = reduxForm({
  form: "recovery-password",
  validate: (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+?\.?[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  },
})(RecoveryPWDContainer);
const selector = formValueSelector("recovery-password");

const bindActions = (dispatch) => {
  return {
    getAccounts: (payload) => dispatch(Auth.authFetchAccounts(payload)),
    goTo: (path) => dispatch(push(path)),
    onRemoveCompanyDomain: (companyDomain) =>
      dispatch(Company.companyRemoveCompanyDomain({ companyDomain })),
    onSelectedCompanyDomain: (companyDomain) =>
      dispatch(Company.companySelectedCompanyDomain({ companyDomain })),
    onRecoveryPWD: (payload) => dispatch(Auth.authRecoveryPWD(payload)),
  };
};

export default connect(
  (state) => ({
    emailSelected: selector(state, "email"),
  }),
  bindActions
)(RecoveryPWDForm);
