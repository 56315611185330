// @flow
import { put, select, call, all, fork, takeLatest } from "redux-saga/effects";
import AuthApi from "../api/Auth";
import { Auth, Company, Users } from "../actions/actions";
import * as Cookies from "js-cookie";

//Sagas for Auth
function* doGetAccounts(action) {
  yield put(Auth.authFetchAccountsRequest());
  window.logger.log("doGetAccounts", action);
  const { body, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to fetch all account releated with some email and tenant
    const { data } = yield call(AuthApi.GetAccounts, api, body);
    const { accountsAvailable } = data;
    yield put(Auth.authFetchAccountsSuccess());
    callbackSuccess(accountsAvailable);
  } catch (err) {
    const { data: errors } = err;
    window.logger.log("ErrorSaga", errors);
    yield put(Auth.authFetchAccountsFailure());
    const { message } = errors || "";
    callbackError(message);
  }
}
function* doSignIn(action) {
  yield put(Auth.authSignInRequest());
  window.logger.log("doSignIn", action);
  const { user, credentials, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    const subdomain = yield select(
      ({ company }) => company.companyDomainSelected
    );
    // call the api to sign in into account
    const { data } = yield call(AuthApi.SignIn, api, {
      tenant: subdomain,
      user,
      ...credentials,
    });
    const { status, user: userId, customerId, token } = data;
    const { localhost, hostname } = yield select(({ config }) => config);
    if (localhost) {
      Cookies.set(
        "authCookie",
        {
          status,
          token,
          user: userId,
          customerId,
          removeDomain: `${hostname}`,
        },
        { domain: `${hostname}` }
      );
    }
    yield put(
      Auth.authSignInSuccess({
        subdomain: subdomain,
        token,
        userId,
      })
    );
    yield put(Company.companySelectedCompanyDomainSignInSuccess());
    callbackSuccess(status === "notActive");
  } catch (err) {
    const { data: errors } = err;
    window.logger.log("ErrorSaga", errors);
    yield put(Auth.authSignInFailure());
    const { message } = errors || "";
    callbackError(message);
  }
}

function* doSignUp(action) {
  yield put(Auth.authSignUpRequest());
  window.logger.log("doSignUp", action);
  const { body, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to sign up users (onboarding process for users into some company)
    yield call(AuthApi.SignUp, api, body);
    yield put(Users.usersSignUpSuccess());
    yield put(Auth.authSignUpSuccess());
    callbackSuccess();
  } catch (err) {
    const { data: errors } = err;
    window.logger.log("ErrorSaga", errors);
    yield put(Auth.authSignUpFailure());
    const { message } = errors || "";
    callbackError(message);
  }
}

function* doActivateAccount(action) {
  yield put(Auth.authActivateAccountRequest());
  window.logger.log("doActivationAccount", action);
  const { body, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to check token for this account
    yield call(AuthApi.ActivateAccount, api, body);
    yield put(Auth.authActivateAccountSuccess());
    callbackSuccess();
  } catch (err) {
    const { data: errors } = err;
    window.logger.log("ErrorSaga", errors);
    yield put(Auth.authActivateAccountFailure());
    const { message } = errors || "";
    callbackError(message);
  }
}

function* doRecoveryPWD(action) {
  yield put(Auth.authRecoveryPWDRequest());
  window.logger.log("doRecoveryPWD", action);
  const { user, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    const subdomain = yield select(
      ({ company }) => company.companyDomainSelected
    );
    // call the api to recover password through your email
    yield call(AuthApi.RecoveryPWD, api, {
      tenant: subdomain,
      user,
    });
    yield put(Auth.authRecoveryPWDSuccess());
    callbackSuccess();
  } catch (err) {
    const { data: errors } = err;
    window.logger.log("ErrorSaga", errors);
    yield put(Auth.authRecoveryPWDFailure());
    const { message } = errors || "";
    callbackError(message);
  }
}

function* doResetPWD(action) {
  yield put(Auth.authResetPWDRequest());
  window.logger.log("doResetPWD", action);
  const { body, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to reset your password
    yield call(AuthApi.ResetPWD, api, body);
    yield put(Auth.authResetPWDSuccess());
    callbackSuccess();
  } catch (err) {
    const { data: errors } = err;
    window.logger.log("ErrorSaga", errors);
    yield put(Auth.authResetPWDFailure());
    const { message } = errors || "";
    callbackError(message);
  }
}

export default function* authSagas(): Generator<*, *, *> {
  yield all([
    fork(takeLatest, Auth.AUTH_FETCH_ACCOUNTS, doGetAccounts),
    fork(takeLatest, Auth.AUTH_SIGNIN, doSignIn),
    fork(takeLatest, Auth.AUTH_SIGNUP, doSignUp),
    fork(takeLatest, Auth.AUTH_ACTIVATE_ACCOUNT, doActivateAccount),
    fork(takeLatest, Auth.AUTH_RECOVERY_PWD, doRecoveryPWD),
    fork(takeLatest, Auth.AUTH_RESET_PWD, doResetPWD),
  ]);
}
