// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AboutContainer from "./AboutContainer";
import ProtectedRoute from "../shared/ProtectedRoute";

const AboutRoute: React.ComponentType<any> = withRouter(
  connect(() => {
    return {
      component: AboutContainer,
      restProps: null,
      isAllowedRoute: true,
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default AboutRoute;
