// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SignUpContainer from "./SignUpContainer";
import ProtectedRoute from "../shared/ProtectedRoute";

const SignUpRoute: React.ComponentType<any> = withRouter(
  connect((state, ownProps) => {
    const { step, subdomain, token } = ownProps.computedMatch.params;
    const { users } = state;
    const { signup } = users;
    window.logger.log("SignUpPrivate", !!signup.stepsAllowed[step]);
    return {
      component: SignUpContainer,
      restProps: { companyDomain: subdomain, token, signup },
      isAllowedRoute: subdomain && !!signup.stepsAllowed[step],
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default SignUpRoute;
