// @flow
import { isMobileBrowser, isTouchableDevice } from "../utils/";

function getBaseDomainOrSuffix(getSuffix = false) {
  const hostname = window.location.hostname;
  if (hostname) {
    if (hostname.indexOf("hetodev.com") > -1) {
      if (getSuffix) {
        return ".com";
      }
      return "hetodev";
    } else {
      if (getSuffix) {
        return ".com";
      }
      if (hostname.indexOf("hetotest.com") > -1) {
        return "hetotest";
      }
      return "hetoonline";
    }
  }
  if (getSuffix) {
    return ".com";
  }
  return "hetoonline";
}
const BASE_DOMAIN = getBaseDomainOrSuffix();
const BASE_SUFFIX = getBaseDomainOrSuffix(true);

// Settings share configured here will be merged into the final config object.
export default {
  //IE11 older: window.location.origin is undefined
  localhost: false,
  origin: window.location.origin
    ? window.location.origin
    : `${window.location.protocol}//${window.location.host}`,
  protocol: `${window.location.protocol}//`,
  hostname: window.location.hostname,
  port: window.location.port.length > 0 ? `:${window.location.port}` : "",
  suffix: BASE_SUFFIX,
  baseDomain: BASE_DOMAIN,
  isMobileBrowser: isMobileBrowser(),
  isTouchableDevice: isTouchableDevice(),
  websitename: process.env.REACT_APP_WEBSITE_NAME,
  version: "1.17.0",
  buildDate: "Wed, 14 Sep 2022 12:00:00 GMT",
  // version: process.env.REACT_APP_VERSION
};
