// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ActivateAccountContainer from "./ActivateAccountContainer";
import ProtectedRoute from "../shared/ProtectedRoute";

const ActivateAccountRoute: React.ComponentType<any> = withRouter(
  connect((state, ownProps) => {
    const { subdomain, token } = ownProps.computedMatch.params;
    return {
      component: ActivateAccountContainer,
      restProps: { companyDomain: subdomain },
      isAllowedRoute: subdomain && token,
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default ActivateAccountRoute;
