// @flow
// Import Features
import * as React from "react";
import axios from "axios";
import { isDevelopmentEnv } from "../../utils/";
// Import Components
import DownloadFilePresentational from "./DownloadFilePresentational";
// Types
type Props = {
  config: Object,
  fileName: string,
  fileId: string,
  from: "ios" | "android",
  tenant: string,
  token: string,
};
type State = {
  filesPreview: ?React$Element<any>,
  statusDownloadFile: "downloading" | "success" | "error",
  errorDownloadFile: string,
};
class DownloadFileContainer extends React.Component<Props, State> {
  // Privates properties:
  _isMounted = false;
  // Public properties:
  state = {
    filesPreview: null,
    statusDownloadFile: "downloading",
    errorDownloadFile: "",
  };
  // <Mounting Component>
  componentDidMount() {
    this._isMounted = true;
    this._onDownloadFile();
  }
  // <Mounting Component>
  // <DOWNLOAD_FILE>
  _onDownloadFile = async () => {
    const { tenant, token, fileId, fileName, config } = this.props;
    const { protocol, base, version: vApi } = config.api;
    let url = `${window.location.protocol}//${tenant}.${window.location.host}${base}/${vApi}/files/download`;
    if (isDevelopmentEnv()) {
      url = `${protocol}${tenant}.${base}/${vApi}/files/download`;
    }
    const response = await axios({
      method: "post",
      url,
      headers: { Authorization: `Bearer ${token}` },
      validateStatus: function validateStatus(status) {
        return status >= 200 && status < 500;
      },
      data: {
        files: [fileId],
      },
      responseType: "blob",
    })
      .then(this._validResponse)
      .catch((error) => {
        window.logger.dir("ERROR 500 from server", error);
        return {
          ok: false,
          data: {},
          errors: "server error 500",
        };
      })
      .then(this._parseResponse);
    if (response.ok) {
      let FilesPreview = await import(`./preview/FilesPreview`);
      FilesPreview = FilesPreview.default;
      const { type, blob } = response;
      this.setState({
        filesPreview: (
          <FilesPreview
            fileDocument={{
              name: fileName,
              type: this._onGetPreviewFileType(type),
              blob,
            }}
            onDownloadFile={() =>
              this._downloadingFile(fileName, response.blob)
            }
          />
        ),
        statusDownloadFile: "success",
        errorDownloadFile: "",
      });
    } else {
      this.setState({
        statusDownloadFile: "error",
        errorDownloadFile: response.message || "",
      });
    }
  };
  // </DOWNLOAD_FILE>
  // <PREVIEW_FILE>
  _onGetPreviewFileType(fileType: string) {
    const isImage = fileType.match(/image\/\w*/gi);
    if (isImage && isImage.length > 0) {
      const imageExtensionArray = fileType.split(/image\//gi);
      if (imageExtensionArray && imageExtensionArray.length > 1) {
        return imageExtensionArray[1];
      }
    }
    switch (fileType) {
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx";
      case "application/pdf":
        return "pdf";
      //Not supported
      case "application/msword":
        return "doc";
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "ppt";
      // Not Used
      case "text/csv":
      case "text/x-csv":
      case "text/comma-separated-values":
      case "text/x-comma-separated-values":
        return "csv";
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx";
      default:
    }
    return fileType;
  }
  // </PREVIEW_FILE>
  // <METHODS_PRIVATES>
  _downloadingFile = (name: string, href: string): void => {
    //this trick will generate a temp <a /> tag to download the file
    const link = document.createElement("a");
    link.href = href;
    link.style.display = "none";
    link.download = name;
    if (document.body && link) {
      document.body.appendChild(link);
      link.click();
    }
    if (document.body && link) {
      document.body.removeChild(link);
    }
  };
  _parseResponse = (response: Object): Object => {
    if (response.headers["content-type"].indexOf("application/json") > -1) {
      return response.apiData;
    } else {
      return {
        ok: true,
        blob: window.URL.createObjectURL(
          new Blob([response.data], {
            type:
              response.headers["content-type"] || "application/octet-stream",
          })
        ),
        type: response.headers["content-type"] || "application/octet-stream",
      };
    }
  };
  _validResponse = async (response: Object): Object => {
    if (response.status === 200) {
      return response;
    } else {
      const apiData = await response.data.text();
      return {
        ...response,
        apiData,
      };
    }
  };
  // </METHODS_PRIVATES>
  // <RENDERS>
  render() {
    const { fileName, from } = this.props;
    const { filesPreview, statusDownloadFile, errorDownloadFile } = this.state;
    return (
      <React.Fragment>
        <DownloadFilePresentational
          errorDownloadFile={
            errorDownloadFile || "Error downloading this document"
          }
          fileName={fileName}
          from={from}
          status={statusDownloadFile}
        />
        {filesPreview}
      </React.Fragment>
    );
  }
  // </RENDERS>
  // <Updating Component>
  // </Updating Component>
  componentWillUnmount() {
    this._isMounted = false;
  }
}
export default DownloadFileContainer;
