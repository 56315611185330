// @flow
import * as React from "react";
import { connect } from "react-redux";
import AboutPresentational from "./AboutPresentational";

type Props = {};

class AboutContainer extends React.Component<Props> {
  componentWillMount() {
    window.scrollTo(0, 0);
    if (document.body && document.body.className) {
      document.body.className = "website";
    }
  }
  render() {
    return <AboutPresentational />;
  }
}

const bindActions = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(null, bindActions)(AboutContainer);
