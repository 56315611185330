// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Adea2022TrialContainer from "./Adea2022Trial.container";
import ProtectedRoute from "../shared/ProtectedRoute";

const Adea2022TrialRoute: React.ComponentType<any> = withRouter(
  connect(() => {
    return {
      component: Adea2022TrialContainer,
      restProps: null,
      isAllowedRoute: true,
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default Adea2022TrialRoute;
