// @flow
import * as React from "react";
import { Element } from "react-scroll";
import Slider from "react-slick";
import Newsletter from "../shared/Newsletter";
import MobileStores from "../shared/MobileStores";
import PreFooter from "../shared/PreFooter";
// Assets
import IphoneFrame from "../../images/iphone-frame.png";
import Slide1_Usermanager from "../../images/slide1-usermanager.png";
import Slide2_Myschoolok from "../../images/slide2-myschoolok.png";
import Slide3_Files from "../../images/slide3-files.png";
import featureEvaluations from "../../images/feature-evaluations.png";
import featureClinicManagement from "../../images/feature-clinic-management.png";
import featureReports from "../../images/feature-reports.png";
import featureAdmin from "../../images/feature-admin.png";
import featureRoles from "../../images/feature-roles.png";
import featurePortfolio from "../../images/feature-portfolio.png";
import featureApprovals from "../../images/feature-approvals.png";
import featureScheduling from "../../images/feature-scheduling.png";
import featureCaseManagement from "../../images/feature-case-management.png";
import featureDocumentationManagement from "../../images/feature-documentation-management.png";
import quotesIcon from "../../images/quotes-icon.png";
import schoolAImage from "../../images/school-a.jpg";
import schoolBImage from "../../images/school-b.jpg";
import processFlowImageLeft from "../../images/process-flow-image.jpg";
import processFlowImage from "../../images/process-flow.png";

const FEATURES_LIST = [
  {
    title: "Scheduling",
    description:
      "Use Heto’s scheduling/rotation feature to assign students to various sites ahead of time per your academic planning calendar. And then track student assignments through clinic management. Students can monitor their upcoming rotations from their dashboard.",
    image: featureScheduling,
  },
  {
    title: "Clinic Management",
    description:
      "Manage all the data associated with the clinic your school works with and assign individual administrators for each of the sites for better accountability. Use features available within clinic management to track preceptors involved, documents needed, and case logs submitted.",
    image: featureClinicManagement,
  },
  {
    title: "Case Management",
    description:
      "A flexible feature within Heto lets you design case logs per your specific school needs. Once the students are assigned to specific sites, they will be able to automatically access the case log feature specific to their sites and use the features available within to enter relevant data. Once done, the case logs are automatically available for respective preceptors’ approval.",
    image: featureCaseManagement,
  },
  {
    title: "Evaluations",
    description:
      "Leverage the most flexible feature within Heto to design evaluation forms for all purposes and not just limiting to evaluations related to students’ community site visits. Our evaluation feature lets you create your own custom rubrics for every year and design templates based on your users/approvers. Take advantage of Heto’s automatic scheduler to trigger evaluations at the right time.",
    image: featureEvaluations,
  },
  {
    title: "Approvals",
    description:
      "Make use of Heto’s approval hierarchy and let the automatic notification process take care of the rest. Through notifications and reminders, Heto ensures the corresponding approvers know what to approve and when to approve, just by looking at their dashboard.",
    image: featureApprovals,
  },
  {
    title: "Student Portfolios",
    description:
      "Students can create their own portfolios and showcase them for better career prospects. The portfolio is not limited to the case logs administered through Heto but also includes various other academic and extra-curricular credentials. A Heto student portfolio complements and enhances LinkedIn profiles.",
    image: featurePortfolio,
  },
  {
    title: "User Role Management and Permissions",
    description:
      "Heto offers multiple user roles to run your school operations effectively. Since the platform handles a lot of different data elements, it’s important you need a system where you can define clear segregation of duties based on job roles and data confidentiality. Heto’s role management and user management tools allow you to create rules for who has permission to access what data elements.",
    image: featureRoles,
  },
  {
    title: "Admin Portal",
    description:
      "Through Heto’s admin portal, your school administrator responsible for administering Heto can customize many features in order to meet specific requirements for your school. The framework used to build Heto is so flexible that you have a lot of control to manage your data the way you want through the Admin Portal.",
    image: featureAdmin,
  },
  {
    title: "Documentation Management",
    description:
      "Not only are student documents and certificates (including immunization/vaccination) critical, but to be able to remind the users when documents and certificates are expiring and/or are coming up for renewal is equally critical too. Use Heto’s document management system to manage all such requirements.",
    image: featureDocumentationManagement,
  },
  {
    title: "Reports",
    description:
      "Take advantage of Heto’s most elaborate and flexible reporting tool to slice and dice your data in whichever way you want. In addition to a few canned reports that are most commonly needed across all Dental Schools in North America, Heto also provides a framework for School administrators to create their own reports at will. The Heto reports dashboard also lets you generate charts for a powerful visual presentation of data.",
    image: featureReports,
  },
];

const CustomArrow = ({
  direction,
  className,
  currentSlide,
  slideCount,
  ...restProps
}: Object): React$Element<any> | null => {
  return (
    <a
      {...restProps}
      className={`${direction} carousel-control`}
      style={{ cursor: "pointer" }}
    >
      <span
        className={`glyphicon glyphicon-chevron-${direction}`}
        aria-hidden="true"
      />
      <span className="sr-only">{direction}</span>
    </a>
  );
};
const settings = {
  dots: false,
  autoplay: true,
  nextArrow: <CustomArrow direction="right" />,
  prevArrow: <CustomArrow direction="left" />,
};

type Props = {
  goToContact: Function,
  goToFeatures: Function,
};

class HomePresentational extends React.Component<Props> {
  render() {
    const { goToContact, goToFeatures } = this.props;
    return (
      <div className="container-fluid body website-home nopadding">
        <section className="presentation text-white">
          <div className="tealtile hidden-xs visible-sm visible-md visible-lg" />
          <div className="row">
            <div className="col-sm-6">
              <div className="content-website content1">
                <h1 className="title mb25 website">
                  A fresh approach
                  <br />
                  to healthcare education management
                </h1>
                <div className="subtitle mb25">
                  Improve the efficiency with which you prepare future
                  healthcare professionals.
                </div>
                <div className="buttons">
                  <button
                    className="more website"
                    onClick={() => goToFeatures("myScrollToFeatures")}
                  >
                    Learn more
                  </button>
                  <button
                    className="touch filled website"
                    onClick={goToContact}
                  >
                    Get in touch
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6 nopadding hidden-xs visible-sm visible-md visible-lg">
              <div className="computer" />
            </div>
          </div>
        </section>

        <section className="what">
          <div className="row">
            <div className="col-sm-6 nopadding hidden-xs visible-sm visible-md visible-lg">
              <div className="ipad" />
            </div>
            <div className="col-sm-6">
              <div className="content-website content2">
                <Element name="myScrollToWhat" />
                <h1 className="title text-teal website">Heto for Dentistry</h1>
                <div className="subtitle">
                  Heto is a cloud-based solution designed and developed to
                  assist institutions in the administration of health science
                  education programs in a very simple and efficient manner. The
                  all-encompassing and powerful Heto engine helps dental schools
                  streamline scheduling, case management, clinic management,
                  assessments, and student portfolios.
                </div>
              </div>
            </div>
            <div className="col-sm-6 nopadding visible-xs hidden-sm hidden-md hidden-lg mt10">
              <div className="ipad" />
            </div>
          </div>
        </section>

        <section className="features text-center">
          <div className="row">
            <div className="col-sm-offset-1 col-sm-10">
              <h1 className="text-teal website">Features</h1>
              <div className="subtitle">
                With Heto, you can monitor the entire process of student-based
                community outreach programs. From scheduling to clinic
                management, and from evaluations of the student performances at
                the individual sites to flexible reporting on the case logs,
                Heto is a flexible platform for managing your data securely,
                efficiently, and easily.
              </div>
              <span
                className="more-information skejule-blue"
                onClick={() => goToFeatures("myScrollToFeaturesMore")}
              >
                More Information
              </span>
            </div>
            <div className="col-sm-12">
              <div className="circles">
                <div className="row">
                  <div className="col-xs-8 col-xs-offset-2 col-sm-2 col-sm-offset-5 nopadding img-responsive mt100 mb100">
                    <div
                      id="iphone-carousel"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="blackbg" />
                      {/* <!-- Wrapper for slides --> */}
                      <div className="carousel-slick-inner" role="listbox">
                        <Slider {...settings}>
                          {[
                            {
                              name: "User Manager",
                              src: Slide1_Usermanager,
                            },
                            {
                              name: "My School",
                              src: Slide2_Myschoolok,
                            },
                            {
                              name: "Files",
                              src: Slide3_Files,
                            },
                          ].map(({ name, src }, index) => (
                            <div key={name} className="item">
                              <img src={src} alt={name} height="480" />
                            </div>
                          ))}
                        </Slider>
                      </div>

                      <div className="frame">
                        <img src={IphoneFrame} alt="iphone-frame" />
                      </div>
                    </div>
                    {/* <!--#iphone-carousel--> */}
                  </div>
                </div>
              </div>
            </div>
            <Element name="myScrollToFeatures" />
            <div className="col-sm-12 p0 mb5">
              <Element name="myScrollToFeaturesMore" />
              <h4 className="website">
                Check out the list of features below to see how Heto can help
                your Dental School.
              </h4>
              <div className="gridcontainer p5">
                {FEATURES_LIST.map((feature, i) => (
                  <div
                    className="tile-card col-md-4 col-sm-6 p0"
                    key={`feature-${feature.title
                      .replace(" ", "-")
                      .toLowerCase()}-${i}`}
                  >
                    <div className="tile-card-inner">
                      <div className="tile-card-front">
                        <div className="icon img-responsive">
                          <img src={feature.image} alt={feature.title} />
                        </div>
                        <span className="featuretitle">{feature.title}</span>
                      </div>
                      <div className="tile-card-back">
                        <span className="featuretitle">{feature.title}</span>
                        <span className="featuredescription">
                          {feature.description}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="testimonials">
          <div className="row">
            <div className="col-sm-12">
              <div className="testimonialcontainer">
                {/* <div className="col-md-2"> */}
                <div className="header ">
                  <img src={quotesIcon} alt="quote-icon" />
                  <h1 className="website">Testimonials</h1>
                </div>
                <div className="visible-sm" />
                {/* </div>
                <div className="col-md-4"> */}
                <div className="schoolwrapper school-a">
                  <img src={schoolAImage} alt="school-a" />
                  <span className="schooltestimonial">
                    Heto has truly empowered our department in how we administer
                    our community education program. The options available in
                    terms of how we want to tailor the solution to our specific
                    needs are exactly what we were looking for, Students and
                    Preceptors have found Heto easy to navigate and use on a
                    daily basis. The mobile application available for tablets
                    has made some of the tasks easier for preceptors. And
                    finally, the flexibility of Heto Reports has made our
                    reports team extremely happy.
                  </span>
                  <span className="schoolname">
                    - Dr. Divesh Byrappagari, Director, University of Detroit
                    Mercy School of Dentistry
                  </span>
                </div>
                {/* </div>
                <div className="col-md-4"> */}
                <div className="schoolwrapper school-b">
                  <img src={schoolBImage} alt="school-b" />
                  <span className="schooltestimonial">
                    Right from the day we went “live” with Heto, there has been
                    a transformation in how we have looked at our data.
                    Everything from scheduling student rotations to looking at
                    evaluation results, the transparency and ease with which we
                    are able to look at the data we want instantaneously have
                    not only made our department more efficient but also have
                    made our leadership team identify the gaps in our student
                    strengths. The Heto support team has been excellent and very
                    responsive to issues we have experienced and in general to
                    any help we have needed. Overall, Heto is just the system we
                    needed for our school.
                  </span>
                  <span className="schoolname">
                    - Dean, Community Education Program, School of Dentistry
                  </span>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="process text-center">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="text-teal website">How does Heto work? </h1>
              <h4 className="website">The Process</h4>
              <div className="processcontainer">
                <div className="processwrapper">
                  <img src={processFlowImageLeft} alt="process-flow-ambient" />
                </div>
                <div className="processwrapper">
                  <img src={processFlowImage} alt="process-flow-description" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Newsletter />
        <PreFooter />
        <MobileStores />
      </div>
    );
  }
}

export default HomePresentational;
