// @flow
//Heto Router
import * as React from "react";
import { Switch, Redirect } from "react-router-dom";
import Menu from "./components/menu/MenuContainer";
import Footer from "./components/shared/Footer";
import ServiceWorker from "./components/service-worker/ServiceWorkerContainer";
import HomeRoute from "./components/home/HomeRoute";
import ContactRoute from "./components/contact/ContactRoute";
import AboutRoute from "./components/about/AboutRoute";
import LegalRoute from "./components/legal/LegalRoute";
import CreateCompanyRoute from "./components/create-company/CreateCompanyRoute";
import SignUpRoute from "./components/signup/SignUpRoute";
import SignInRoute from "./components/signin/SignInRoute";
import ActivateAccountRoute from "./components/activate-account/ActivateAccountRoute";
import RecoveryPWDRoute from "./components/recovery-pwd/RecoveryPWDRoute";
import ResetPWDRoute from "./components/reset-pwd/ResetPWDRoute";
import PublicUrlRoute from "./components/public-url/PublicUrlRoute";
import DownloadFileRoute from "./components/download-file/DownloadFileRoute";

// TEMP ONLY FOR CONFERENCE
import Adea2022TrialRoute from "./components/adea2022trial/Adea2022Trial.route";
// ////////////////////////

const WebsiteRouter = (): React$Element<string> => (
  <Switch>
    <HomeRoute path="/" exact />
    <ContactRoute path="/contact" exact />
    <AboutRoute path="/about" exact />
    <LegalRoute path="/legal" exact />
    <CreateCompanyRoute path="/create/:step/:token?" />
    <SignUpRoute path="/signup/:step/:subdomain?/:token?" />
    <SignInRoute path="/signin" exact />
    <ActivateAccountRoute path="/activation-account/:subdomain/:token" exact />
    <RecoveryPWDRoute path="/recovery-password" exact />
    <ResetPWDRoute path="/reset-password/:subdomain/:token" exact />
    <PublicUrlRoute path="/public/:tenant/:url/:dataId" exact />
    <DownloadFileRoute
      path="/download-file/:from/:tenant/:token/:fileId/:fileName"
      exact
    />
    {/* TEMP ONLY FOR CONFERENCE */}
    <Adea2022TrialRoute path="/adea2022trial" exact />
    {/* //////////////////////// */}
    <Redirect to="/" />
  </Switch>
);

const HetoRouter = (): React$Element<string> => (
  <main>
    <Menu key="heto-menu" />
    <WebsiteRouter />
    <Footer key="heto-footer" />
    <ServiceWorker key="heto-button-service-worker" />
  </main>
);

export default HetoRouter;
