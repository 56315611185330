// @flow
// Import Features
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { PublicUrl } from "../../actions/actions";
// Import Components
import PublicUrlPresentational from "./PublicUrlPresentational";
// Import styles
import "./PublicUrl.css";
// Types
type Props = {
  config: Object,
  dataId: string,
  getPublicUrlDatas: (payload: Object) => void,
  goTo: (payload: any) => void,
  tenant: string,
  url: "metric-pages" | "portfolios",
};
type State = {
  component: ?React.ElementType,
  data: ?Object,
  errorPublicUrl: string,
  fetchingDatas: boolean,
  showPublicUrl: boolean,
};
class PublicUrlContainer extends React.Component<Props, State> {
  // Privates properties:
  _isMounted = false;
  // Public properties:
  state = {
    component: null,
    data: null,
    errorPublicUrl: "",
    fetchingDatas: true,
    showPublicUrl: false,
  };
  // <Mounting Component>
  componentDidMount() {
    this._isMounted = true;
    this._onGetPublicUrlDatas();
  }
  // <Mounting Component>
  // <GET_PUBLIC_URL: PORTFOLIO | REPORTS-CHARTS>
  _onGetPublicUrlDatas = () => {
    const onEndCallback = () => {
      this.setState({ fetchingDatas: false });
    };
    const { goTo } = this.props;
    this._onGettingPublicUrlDatas()
      .then(onEndCallback)
      .catch(() => {
        onEndCallback();
        setTimeout(() => {
          goTo("/");
        }, 5000);
      });
  };
  _onGettingPublicUrlDatas = () => {
    const { url, tenant, dataId, getPublicUrlDatas } = this.props;
    return new Promise((resolve, reject) => {
      const formValues = {
        url,
        tenant,
        dataId,
      };
      const callbacks = {
        callbackError: (errorPublicUrl) => {
          window.logger.log(
            "PublicUrl - error - _onGettingPublicUrlDatas",
            url,
            tenant,
            dataId,
            errorPublicUrl
          );
          this.setState({ errorPublicUrl });
          reject();
        },
        callbackSuccess: (data: Object) => {
          window.logger.log(
            "PublicUrl - success - _onGettingPublicUrlDatas",
            data
          );
          this.setState({ data });
          this._loadingComponent(resolve);
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      getPublicUrlDatas(payload);
    });
  };
  // </GET_PUBLIC_URL: PORTFOLIO | REPORTS-CHARTS>
  // <METHODS_PRIVATES>
  _loadingComponent = (resolve: () => void) => {
    const { url } = this.props;
    let componentType = url
      .split("-")
      .map(
        (partialUrlName) =>
          partialUrlName.charAt(0).toUpperCase() + partialUrlName.slice(1)
      )
      .join("");
    window.logger.log("_loadingComponent", url, componentType);
    (async () => {
      const component = await import(`./${url}/${componentType}Container`);
      this.setState({
        component: component.default,
      });
      setTimeout(() => {
        this.setState({ showPublicUrl: true });
        resolve();
      }, 500);
    })();
  };
  // </METHODS_PRIVATES>
  // <METHODS_PUBLICS>
  onSetImg = (_img: string, updatedAt: string) => {
    const { config, tenant } = this.props;
    return [
      config.baseImg.replace("{tenant}", tenant),
      _img,
      `&updatedAt=${updatedAt}`,
    ].join("");
  };
  // </METHODS_PUBLICS>
  // <RENDERS>
  render() {
    const {
      fetchingDatas,
      errorPublicUrl,
      component: Component,
      ...remainState
    } = this.state;
    if (!remainState.showPublicUrl) {
      return (
        <PublicUrlPresentational
          checkingPublicUrl={fetchingDatas}
          errorPublicUrl={errorPublicUrl || "Error Public Url"}
        />
      );
    }
    return Component && <Component {...remainState} setImg={this.onSetImg} />;
  }
  // </RENDERS>
  // <Updating Component>
  // </Updating Component>
  componentWillUnmount() {
    this._isMounted = false;
  }
}
export default connect(null, (dispatch) => ({
  getPublicUrlDatas: (payload) => dispatch(PublicUrl.publicUrl(payload)),
  goTo: (path) => dispatch(push(path)),
}))(PublicUrlContainer);
