// @flow
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Auth } from "../../actions/actions";
import ActivateAccountPresentational from "./ActivateAccountPresentational";
import "./ActivationAccount.css";

type Props = {
  companyDomain: string,
  match: Object,
  activationAccount: Function,
  goTo: Function,
};
type State = {
  checkingToken: boolean,
  isValidToken: boolean,
  errorMessage: string,
};

class ActivateAccountContainer extends React.Component<Props, State> {
  state = {
    checkingToken: true,
    isValidToken: true,
    errorMessage: "",
  };
  onActivationAccount = (subdomain, token) => {
    const { activationAccount } = this.props;
    return new Promise((resolve, reject) => {
      const formValues = {
        body: {
          confirmationToken: token,
          tenant: subdomain,
        },
      };
      const callbacks = {
        callbackError: (msg) => {
          window.logger.log("error", msg);
          reject(msg ? msg : "");
        },
        callbackSuccess: () => {
          window.logger.log("Success");
          resolve();
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      activationAccount(payload);
    });
  };
  onCheckToken = () => {
    const {
      match: {
        params: { subdomain, token },
      },
      goTo,
    } = this.props;
    const callbackSuccess = () => {
      this.setState({ checkingToken: false, isValidToken: true });
      setTimeout(() => {
        goTo({
          pathname: "/signin",
          state: { companyDomain: subdomain },
        });
      }, 5000);
    };
    const callbackError = (message) => {
      this.setState({
        checkingToken: false,
        isValidToken: false,
        errorMessage: message,
      });
      setTimeout(() => {
        goTo("/");
      }, 5000);
    };
    if (subdomain && token) {
      this.onActivationAccount(subdomain, token)
        .then(callbackSuccess)
        .catch(callbackError);
    } else {
      callbackError();
    }
  };
  componentDidMount() {
    this.onCheckToken();
  }
  render() {
    const { companyDomain } = this.props;
    const { checkingToken, isValidToken, errorMessage } = this.state;
    return (
      <ActivateAccountPresentational
        companyDomain={companyDomain}
        checkingToken={checkingToken}
        isValidToken={isValidToken}
        errorMessage={errorMessage}
      />
    );
  }
}

const bindActions = (dispatch) => {
  return {
    activationAccount: (payload) => dispatch(Auth.authActivateAccount(payload)),
    goTo: (path) => dispatch(push(path)),
  };
};

export default connect(null, bindActions)(ActivateAccountContainer);
