// @flow
import { put, select, call, all, fork, takeLatest } from "redux-saga/effects";
import PublicUrlApi from "../api/PublicUrl";
import { PublicUrl } from "../actions/actions";

// Fetch Public Url Datas
function* doFetchPublicUrlData(action) {
  yield put(PublicUrl.publicUrlRequest());
  window.logger.log("doFetchPublicUrlData", action);
  const {
    url,
    tenant,
    dataId,
    callbackSuccess,
    callbackError,
  } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to get some datas for public url
    const { data } = yield call(
      PublicUrlApi.GetPublicUrlDatas,
      api,
      url,
      tenant,
      dataId
    );
    yield put(PublicUrl.publicUrlSuccess());
    callbackSuccess(data);
  } catch (error) {
    window.logger.log("ErrorSaga - doFetchPublicUrlData", error);
    const { data: errors } = error;
    const { message, details } = errors || "";
    yield put(PublicUrl.publicUrlFailure());
    let errorMsg = message;
    if (details && details.length > 0) {
      errorMsg = `${errorMsg}: ${details[0].msg}`;
    }
    callbackError(errorMsg);
  }
}
export default function* publicUrlSagas(): Generator<*, *, *> {
  yield all([fork(takeLatest, PublicUrl.PUBLIC_URL, doFetchPublicUrlData)]);
}
