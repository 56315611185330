// @flow
//Class to manage all api calls
import axios from "axios";

// Private properties (problem with memory leak, we'll finder another solution)
// variables:
let _: Object = new WeakMap();
//Functions:
function fullURL(baseUrl, url: string): string {
  return baseUrl + url;
}
function getHeaders(
  token,
  isPostPut: boolean = false,
  isPostFile: boolean = false
): Object {
  let headers = {
    Accept: "application/json",
  };
  if (token && token.length) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }
  if (isPostPut && !isPostFile) {
    headers = {
      ...headers,
      "Content-type": "application/json",
    };
  }
  return headers;
}
function configPostPut(
  token: string,
  method: string,
  data: mixed,
  isPostFile: boolean = false
): Object {
  return {
    method: method,
    headers: getHeaders(token, true, isPostFile),
    data: !isPostFile ? JSON.stringify(data) : data,
    validateStatus: function validateStatus(status) {
      return status >= 200 && status < 500;
    },
  };
}
function validResponse(response: Object): Object {
  if (response.headers["content-type"].indexOf("application/json") > -1) {
    return response.data;
  }
  return {
    ok: false,
    data: {},
    errors: "content-type is not application/json",
  };
}
function parseResponse(response: Object): Promise<*> {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      resolve({ success: true, data: response });
    } else {
      reject({ success: false, data: response });
    }
  });
}

export class API {
  initAPI: boolean;
  constructor(url: string, token: string = "") {
    _.set(this, { baseUrl: url, token: token });
    this.initAPI = true;
  }

  getBaseUrl() {
    const { baseUrl } = _.get(this);
    return baseUrl;
  }

  setBaseUrl(baseUrl: string): void {
    const { token } = _.get(this);
    _.set(this, { baseUrl: baseUrl, token: token });
  }

  setToken(token: string): void {
    const { baseUrl } = _.get(this);
    _.set(this, { baseUrl: baseUrl, token: token });
  }

  GET(url: string): Promise<*> {
    const { baseUrl, token } = _.get(this);
    return axios
      .get(fullURL(baseUrl, url), {
        headers: getHeaders(token),
        validateStatus: function validateStatus(status) {
          return status >= 200 && status < 500;
        },
      })
      .then(validResponse)
      .catch((error) => {
        window.logger.dir("ERROR 500 from server", error);
        return {
          ok: false,
          data: {},
          errors: "server error 500",
        };
      })
      .then(parseResponse);
  }

  POST(url: string, data: mixed, isPostFile: boolean = false): Promise<*> {
    const { baseUrl, token } = _.get(this);
    let postbody;
    if (!isPostFile) {
      postbody = { ...data };
    } else {
      postbody = data;
    }
    return axios(
      fullURL(baseUrl, url),
      configPostPut(token, "POST", postbody, isPostFile)
    )
      .then(validResponse)
      .catch((error) => {
        window.logger.dir("ERROR 500 from server", error);
        return {
          ok: false,
          data: {},
          errors: "server error 500",
        };
      })
      .then(parseResponse);
  }

  PUT(url: string, data: mixed, isPostFile: boolean = false): Promise<*> {
    const { baseUrl, token } = _.get(this);
    let postbody;
    if (!isPostFile) {
      postbody = { ...data };
    } else {
      postbody = data;
    }
    return axios(
      fullURL(baseUrl, url),
      configPostPut(token, "PUT", postbody, isPostFile)
    )
      .then(validResponse)
      .catch((error) => {
        window.logger.dir("ERROR 500 from server", error);
        return {
          ok: false,
          data: {},
          errors: "server error 500",
        };
      })
      .then(parseResponse);
  }

  DELETE(url: string): Promise<*> {
    const { baseUrl, token } = _.get(this);
    return axios(fullURL(baseUrl, url), {
      method: "DELETE",
      headers: getHeaders(token),
      validateStatus: function validateStatus(status) {
        return status >= 200 && status < 500;
      },
    })
      .then(validResponse)
      .catch((error) => {
        window.logger.dir("ERROR 500 from server", error);
        return {
          ok: false,
          data: {},
          errors: "server error 500",
        };
      })
      .then(parseResponse);
  }
}
