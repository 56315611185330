// @flow
import * as React from "react";
import LegalPresentational from "./LegalPresentational";
import "./Legal.css";

type Props = {};

class LegalContainer extends React.Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (document.body && document.body.className) {
      document.body.className = "website";
    }
  }
  render() {
    return <LegalPresentational />;
  }
}

export default LegalContainer;
